import { FormError } from '@components/FormError/FormError'
import { SplitPanel } from '@components/SplitPanel/SplitPanel'
import { useAppSelector } from '@hooks/redux'
import { MutationError } from '@interfaces/mutationError'
import { Avatar, Box, Button, Group, Stack, Text } from '@mantine/core'
import { selectAccountInformation } from '@redux/account/accountSlice'
import { useConnectMutation } from '@redux/auth/authEndpoints'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ConnectSuccess } from './modals/ConnectSuccess'

export const Connect = (): JSX.Element => {
  const [queryParams] = useSearchParams()
  const user = useAppSelector(selectAccountInformation)
  const [formError, setFormError] = useState<MutationError | null>()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const [connect] = useConnectMutation()

  const code = queryParams.get('code')
  const seller = queryParams.get('seller') ?? ''
  const id = queryParams.get('id') ?? ''

  const handleConnect = async () => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      await connect({ code, userId: id }).unwrap()
      setIsSuccess(true)
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <SplitPanel title="Connect" hideTitles>
        <Group>
          <Avatar radius="xl" color="pink" />
          <Box sx={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {user.name}
            </Text>
            <Text color="dimmed" size="xs">
              {user.email}
            </Text>
          </Box>
        </Group>
        <Text mt="xl" mb="md">
          You've been invited to join{' '}
          <Text color="violet" span>
            {seller}
          </Text>
          's team.
        </Text>
        <Stack>
          {formError && <FormError error={formError} />}
          <Button onClick={handleConnect} loading={isSubmitting}>
            Join {seller}
          </Button>
        </Stack>
        {isSuccess && <ConnectSuccess seller={seller} />}
      </SplitPanel>
    </>
  )
}
