import { Content } from '@components/Content/Content'
import { useSetSection } from '@hooks/useSetSection'
import { useGetBrandsQuery } from '@redux/brand/brandEndpoints'
import { Outlet } from 'react-router-dom'

export const Base = (): JSX.Element => {
  useSetSection('Brands')

  const { data: brandEntityState, isLoading, error } = useGetBrandsQuery()

  return (
    <Content>
      <Outlet context={{ brandEntityState, isLoading, error }} />
    </Content>
  )
}
