import * as Yup from 'yup'

export const signupSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], 'Passwords must match'),
})

export type SignupState = Yup.InferType<typeof signupSchema>

export const signupInitialState: () => SignupState = () => ({
  name: '',
  password: '',
  confirmPassword: '',
})
