import { Modal } from '@components/Modal/Modal'
import { RoutePaths } from '@interfaces/routePaths'
import { Anchor, Stack, Text } from '@mantine/core'
import { useNavigate } from 'react-router-dom'

export interface SignupSuccessProps {
  email: string
}

export const SignupSuccess = ({ email }: SignupSuccessProps): JSX.Element => {
  const navigate = useNavigate()

  const onCloseHandler = () => navigate(RoutePaths.LOGIN)

  return (
    <Modal opened={true} title="Success" onClose={onCloseHandler} hideClose>
      <Stack>
        <Text data-autofocus>
          Thank you for signing up for NabThis! We've sent an email address to{' '}
          <Text span color="pink">
            {email}
          </Text>
          . Before you login you must verify your email address.
        </Text>
        <Anchor href={RoutePaths.LOGIN}>Login</Anchor>
      </Stack>
    </Modal>
  )
}
