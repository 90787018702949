import * as Yup from 'yup'

export const createSellerSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  website: Yup.string().required('Website is required'),
  description: Yup.string().required('Description is required'),
  hours: Yup.array()
    .of(
      Yup.object({
        day: Yup.string(),
        open: Yup.string(),
        close: Yup.string(),
      }),
    )
    .optional(),
})

export type CreateSellerState = Yup.InferType<typeof createSellerSchema>

export const createSellerInitialState: () => CreateSellerState = () => ({
  name: '',
  address: '',
  website: '',
  description: '',
  hours: [{ day: '', open: '', close: '' }],
})
