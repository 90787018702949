import * as Yup from 'yup'

export const inviteSchema = Yup.object({
  email: Yup.string().required('Email is required').email(),
  role: Yup.string().required('Role is required'),
})

export type InviteState = Yup.InferType<typeof inviteSchema>

export const inviteInitialState: () => InviteState = () => ({
  email: '',
  role: '',
})
