import { RoutePaths } from '@interfaces/routePaths'
import { Base } from '@views/Account/Base'
import { ChangePassword } from '@views/Account/ChangePassword'
import { Details } from '@views/Account/Details'
import { RouteObject } from 'react-router-dom'

export const ACCOUNT_PATHS: RouteObject[] = [
  {
    path: RoutePaths.ACCOUNT,
    element: <Base />,
    children: [
      {
        path: RoutePaths.ACCOUNT_DETAILS,
        element: <Details />,
      },
      {
        path: RoutePaths.ACCOUNT_CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
    ],
  },
]
