import { Content } from '@components/Content/Content'
import { useSetSection } from '@hooks/useSetSection'
import { Outlet } from 'react-router-dom'

export const Base = (): JSX.Element => {
  useSetSection('Products')

  return (
    <Content>
      <Outlet />
    </Content>
  )
}
