import { Attribute } from '@interfaces/attribute/attribute'
import { AttributeResponse } from '@interfaces/attribute/attributeResponse'

export function attributesToObject(
  attributes: AttributeResponse[] = [],
): Attribute {
  return attributes.reduce(
    (acc: Record<string, string>, attr: AttributeResponse) => {
      acc[attr?.attributeName] = attr?.attributeValue
      return acc
    },
    {},
  )
}
