import { Logo } from '@components/Logo/Logo'
import {
  Box,
  Button,
  Flex,
  MediaQuery,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

export interface SplitPanelProps {
  title: ReactNode
  children: ReactNode | ReactNode[]
  subtitle?: ReactNode | ReactNode[]
  hideTitles?: boolean
  backLink?: string
  backText?: ReactNode
}

export const SplitPanel = (props: SplitPanelProps): JSX.Element => {
  const {
    title,
    hideTitles = false,
    subtitle,
    children,
    backLink,
    backText,
  } = props

  const navigate = useNavigate()

  return (
    <Flex
      sx={() => ({
        width: '100vw',
        height: '100vh',
      })}
    >
      <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
        <Box
          p={80}
          sx={(theme) => ({
            width: '450px',
            maxWidth: '450px',
            minWidth: '450px',
            flexGrow: 0,
            backgroundColor: theme.colors.gray[0],
            height: '100vh',
          })}
        >
          <Stack>
            <Box ml={2}>
              <Logo size="sm" />
            </Box>
            <Title mt="md" color="gray.7">
              Polaris Seller Platform.
            </Title>
            <Text>
              Product and inventory management for active NabThis sellers.
            </Text>
          </Stack>
        </Box>
      </MediaQuery>
      <Box
        p={80}
        sx={() => ({
          width: '100%',
          backgroundColor: 'white',
          height: '100vh',
          flexGrow: 1,
        })}
      >
        <Flex
          sx={() => ({
            width: '100%',
            height: '100%',
          })}
          align="center"
          justify="center"
        >
          <Box style={{ flexGrow: 1, maxWidth: '475px' }}>
            {!hideTitles && (
              <>
                <Title order={2} mb={subtitle ? 'sm' : 30}>
                  {title}
                </Title>
                {subtitle && (
                  <Text size="sm" color="gray.6" mb={20}>
                    {subtitle}
                  </Text>
                )}
              </>
            )}
            {children}
            {backLink && backText && (
              <Button
                mt="xl"
                onClick={() => navigate(backLink)}
                leftIcon={<IconArrowNarrowLeft />}
                variant="white"
                color="gray"
              >
                Back to {backText}
              </Button>
            )}
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}
