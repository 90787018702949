import { Wishlist } from '@interfaces/entities/wishlist/wishlist'
import { WishlistPreferences } from '@interfaces/entities/wishlist/wishlistPreferences'
import { WishlistPreferencesResponse } from '@interfaces/entities/wishlist/wishlistPreferencesResponse'
import { WishlistResponse } from '@interfaces/entities/wishlist/wishlistResponse'
import { wishlistPreferencesSerializer } from './wishlistPreferencesSerializer'

export function wishlistSerializer(wishlist: WishlistResponse): Wishlist {
  const { id, user, label, preferences: prefs } = wishlist

  const preferences = prefs.reduce(
    (acc: WishlistPreferences, curr: WishlistPreferencesResponse) => {
      const serialized = wishlistPreferencesSerializer(curr)

      acc = {
        ...acc,
        ...serialized,
      }

      return acc
    },
    {} as WishlistPreferences,
  )

  return {
    id,
    user,
    label,
    preferences,
  }
}
