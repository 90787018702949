import * as Yup from 'yup'

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string().required('New Password is required'),
  confirmNewPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

export type ChangePasswordState = Yup.InferType<typeof changePasswordSchema>

export const changePasswordInitialState: () => ChangePasswordState = () => ({
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
})
