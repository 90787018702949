import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { CardSection, Group, Stack, Text } from '@mantine/core'
import { selectAccountInformation } from '@redux/account/accountSlice'

export const Details = (): JSX.Element => {
  const user = useAppSelector(selectAccountInformation)

  return (
    <Section title="Details">
      <CardSection p="md">
        <Stack>
          <Group>
            <Text size="md" fw="bold">
              Name
            </Text>
            <Text size="md">{user.name}</Text>
          </Group>
          <Group>
            <Text size="md" fw="bold">
              Email
            </Text>
            <Text size="md">{user.email}</Text>
          </Group>
        </Stack>
      </CardSection>
    </Section>
  )
}
