import { FormError } from '@components/FormError/FormError'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { ProductCategory } from '@interfaces/entities/product/productCategory'
import { ProductColors } from '@interfaces/entities/product/productColors'
import { ProductCondition } from '@interfaces/entities/product/productCondition'
import { ProductDepartment } from '@interfaces/entities/product/productDepartment'
import { ProductSize } from '@interfaces/entities/product/productSize'
import { ProductType } from '@interfaces/entities/product/productType'
import { MutationError } from '@interfaces/mutationError'
import {
  ActionIcon,
  Box,
  Button,
  CardSection,
  FileButton,
  Group,
  Image,
  Modal,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { useCreateProductMutation } from '@redux/product/productEndpoints'
import { selectActiveSeller } from '@redux/site/siteSlice'
import {
  createVintageProductInitialState,
  createVintageProductSchema,
  CreateVintageProductState,
} from '@schemas/product/createVintageProduct'
import {
  IconArrowLeft,
  IconArrowRight,
  IconArrowsMaximize,
  IconBarcode,
  IconCategory,
  IconCloudUpload,
  IconColorFilter,
  IconCurrencyDollar,
  IconHanger,
  IconPhoto,
  IconPhotoMinus,
  IconPlus,
  IconRulerMeasure,
  IconSparkles,
  IconTrademark,
} from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import currency from 'currency.js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const CreateVintageProduct = (): JSX.Element => {
  const navigate = useNavigate()
  const seller = useAppSelector(selectActiveSeller)
  const category = ProductCategory.VINTAGE

  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()

  const [createProduct, { isSuccess }] = useCreateProductMutation()

  const {
    onSubmit,
    isDirty,
    getInputProps,
    values,
    insertListItem,
    removeListItem,
    setFieldValue,
  } = useForm({
    initialValues: createVintageProductInitialState(),
    validate: yupResolver(createVintageProductSchema),
  })

  const handleClose = () => navigate(`../${category}`)

  const handleSubmit = async (values: CreateVintageProductState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      const { photos: photoList, ...rest } = values

      const price = parseInt(
        String(Math.ceil(currency(rest.price).value * 100)),
      )

      const photos = photoList?.map((curr) => {
        if (curr.photo) return curr.photo
      })

      const captions = photoList?.map((curr) => {
        if (curr.photo) return curr.caption
      })

      const _values = {
        category,
        sellerId: seller.id,
        ...rest,
        photos,
        captions: captions || [],
        price,
      }

      await createProduct(_values).unwrap()

      showNotification({
        message: 'Product created!',
        color: 'green',
      })

      handleClose()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  const COLOR_VALUES = Object.values(ProductColors).map((color: string) => ({
    label: color,
    value: color,
  }))

  const SIZE_VALUES = Object.values(ProductSize).map((size: string) => ({
    label: size,
    value: size,
  }))

  const CONDITION_VALUES = Object.values(ProductCondition).map(
    (condition: string) => ({
      label: condition,
      value: condition,
    }),
  )

  const DEPARTMENT_VALUES = Object.values(ProductDepartment).map(
    (department: string) => ({
      label: department,
      value: department,
    }),
  )

  const TYPE_VALUES = Object.values(ProductType).map((type: string) => ({
    label: type,
    value: type,
  }))

  const addPhotoItem = () => {
    insertListItem('photos', {
      photo: null,
      caption: '',
    })
  }

  const removePhotoItem = (index: number) => {
    if (values.photos && values.photos.length > 1)
      removeListItem('photos', index)
  }

  const movePhotoItem = (oldIndex: number, newIndex: number) => {
    setFieldValue(
      'photos',
      arrayMoveImmutable(values?.photos ?? [], oldIndex, newIndex),
    )
  }

  const [fullImageView, { open, close }] = useDisclosure(false)
  const [currentMediaIndex, setCurrentMediaIndex] = useState<number | null>(
    null,
  )

  const handleOpenModal = (index: number) => {
    setCurrentMediaIndex(index)
    open()
  }

  const selectedImage =
    currentMediaIndex !== null
      ? values.photos?.[currentMediaIndex]?.photo
      : null
  const selectedImageUrl = selectedImage
    ? URL.createObjectURL(selectedImage as Blob)
    : ''

  const getPreview = (index: number) => {
    if (!values.photos?.[index]?.photo) return <></>
    const imageUrl = URL.createObjectURL(values.photos?.[index]?.photo as Blob)

    return (
      <>
        <Box
          w={200}
          h={200}
          sx={(theme) => ({
            position: 'relative',
            overflow: 'hidden',
            border: `solid 1px ${theme.colors.gray[4]}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover .action-icon': {
              display: 'flex',
            },
          })}
        >
          <Image src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} />
          <Tooltip
            label="View Full Image"
            color="dark"
            withArrow
            arrowSize={6}
            sx={{
              fontSize: '0.75rem',
              padding: '4px 8px',
            }}
          >
            <ActionIcon
              className="action-icon"
              color="dark"
              variant="light"
              size="md"
              sx={{
                display: 'none',
                position: 'absolute',
                bottom: '8px',
                right: '8px',
              }}
              onClick={() => handleOpenModal(index)}
            >
              <IconArrowsMaximize size="1rem" />
            </ActionIcon>
          </Tooltip>
        </Box>
        {selectedImage && (
          <Modal
            opened={fullImageView}
            onClose={close}
            size="auto"
            sx={{
              display: 'flex',
              img: {
                maxWidth: '100%',
                maxHeight: 'calc(100vh - 40px)',
              },
            }}
          >
            <Image
              src={selectedImageUrl}
              onLoad={() => URL.revokeObjectURL(selectedImageUrl)}
            />
          </Modal>
        )}
      </>
    )
  }
  const photoFields =
    values.photos &&
    values.photos.map((_, index) => {
      return (
        <Group key={`photos-${index}`} spacing="xs" align="flex-start">
          <Stack spacing="xs">
            {values?.photos?.[index].photo && getPreview(index)}
            {!values?.photos?.[index].photo && (
              <Box
                p="md"
                w={200}
                h={200}
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `solid 1px ${theme.colors.gray[4]}`,
                  backgroundColor: theme.colors.gray[1],
                })}
              >
                <IconPhoto size="2rem" />
              </Box>
            )}
            <Group grow spacing="xs">
              {values?.photos?.[index].photo && index === 0 ? (
                <ActionIcon variant="outline" color="teal">
                  <Text size="xs" fw={700}>
                    Primary
                  </Text>
                </ActionIcon>
              ) : (
                <ActionIcon
                  variant="outline"
                  color="gray"
                  disabled={
                    !values?.photos?.[index].photo ||
                    index === 0 ||
                    !values?.photos?.[index - 1].photo
                  }
                  onClick={() => movePhotoItem(index, index - 1)}
                >
                  <IconArrowLeft size="1rem" />
                </ActionIcon>
              )}
              <ActionIcon
                variant="outline"
                disabled={
                  !values?.photos?.[index].photo ||
                  index + 1 === values?.photos?.length ||
                  !values?.photos?.[index + 1].photo
                }
                onClick={() => movePhotoItem(index, index + 1)}
              >
                <IconArrowRight size="1rem" />
              </ActionIcon>
            </Group>
            {!values?.photos?.[index].photo && (
              <FileButton
                accept="image/png,image/jpeg,image/jpg"
                {...getInputProps(`photos.${index}.photo`)}
              >
                {(props) => (
                  <Button
                    variant="outline"
                    leftIcon={<IconCloudUpload size="0.9rem" />}
                    disabled={values?.photos?.[index].photo ? true : false}
                    {...props}
                  >
                    Select Image
                  </Button>
                )}
              </FileButton>
            )}

            {(values?.photos?.[index]?.photo || index !== 0) && (
              <>
                <TextInput
                  placeholder="Add Caption"
                  style={{ flexGrow: 1 }}
                  {...getInputProps(`photos.${index}.caption`)}
                />
                <Button
                  variant="outline"
                  size="xs"
                  color="red"
                  leftIcon={<IconPhotoMinus size="0.9rem" />}
                  onClick={() => removePhotoItem(index)}
                >
                  Remove
                </Button>
              </>
            )}
          </Stack>
        </Group>
      )
    })

  return (
    <>
      <form
        onSubmit={onSubmit((values: CreateVintageProductState) =>
          handleSubmit(values),
        )}
      >
        <Section title="Create Vintage Product">
          <CardSection p="md">
            {!isSuccess && formError && <FormError error={formError} />}

            <TextInput
              label="Title"
              description="Enter a title for the product"
              placeholder="Title"
              data-autofocus
              withAsterisk
              {...getInputProps('title')}
            />
          </CardSection>
        </Section>

        <Stack pt="md">
          <Section title="Product Media">
            <CardSection p="md">
              <SimpleGrid cols={3} spacing="xl">
                {photoFields}
                <Group spacing="xs" align="flex-start">
                  <Stack>
                    <Box
                      p="md"
                      w={200}
                      h={200}
                      sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `solid 1px ${theme.colors.gray[4]}`,
                        backgroundColor: theme.colors.gray[0],
                        cursor: 'pointer',
                      })}
                      onClick={addPhotoItem}
                    >
                      <IconCloudUpload size="2rem" />
                    </Box>
                    <Button
                      variant="outline"
                      leftIcon={<IconPlus size="0.9rem" />}
                      onClick={addPhotoItem}
                    >
                      Add Another Image
                    </Button>
                  </Stack>
                </Group>
              </SimpleGrid>
            </CardSection>
          </Section>
        </Stack>

        <Stack pt="md">
          <Section title="Product Details">
            <CardSection p="md">
              <Stack>
                <Textarea
                  label="Description"
                  description="Enter a brief description of the product"
                  placeholder="Description"
                  autosize
                  minRows={3}
                  withAsterisk
                  {...getInputProps('description')}
                />
                <TextInput
                  label="SKU"
                  description="Enter the SKU of your product"
                  icon={<IconBarcode size="1rem" />}
                  placeholder="SKU"
                  withAsterisk
                  {...getInputProps('sku')}
                />
                <TextInput
                  label="Price"
                  description="Enter Price in USD"
                  icon={<IconCurrencyDollar size="1rem" />}
                  placeholder="Price"
                  withAsterisk
                  {...getInputProps('price')}
                />
                <Select
                  label="Type"
                  description="Select the type of the product. Such as: Sweatshirts, Pants, Jackets, etc."
                  icon={<IconHanger size="1rem" />}
                  placeholder="Select Type"
                  data={TYPE_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('type')}
                />
                <TextInput
                  label="Brand"
                  description="Enter the brand/manufacturer name of the product"
                  icon={<IconTrademark size="1.1rem" />}
                  placeholder="Brand"
                  withAsterisk
                  {...getInputProps('brand')}
                />
                <Select
                  label="Department"
                  description="Which department does this product belong to?"
                  icon={<IconCategory size="1rem" />}
                  placeholder="Select Department"
                  data={DEPARTMENT_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('department')}
                />
                <Select
                  label="Size"
                  description="Please refer to our size chart and select the appropriate sizing"
                  icon={<IconRulerMeasure size="1rem" />}
                  placeholder="Select Size"
                  data={SIZE_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('size')}
                />
                <Select
                  label="Color"
                  description="Select a color that best describes the product"
                  icon={<IconColorFilter size="1rem" />}
                  placeholder="Select Color"
                  data={COLOR_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('color')}
                />
                <Select
                  label="Condition"
                  description="Please follow our guidelines carefully when selecting the condition"
                  icon={<IconSparkles size="1rem" />}
                  placeholder="Select Condition"
                  data={CONDITION_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('condition')}
                />
                <Textarea
                  label="Stains and Imperfections"
                  description="Enter information on stains and imperfections"
                  placeholder="Stains and Imperfections"
                  autosize
                  minRows={3}
                  {...getInputProps('stainsAndImperfections')}
                />
                <Textarea
                  label="Measurements"
                  description="Enter custom measurements, if any"
                  placeholder="Measurements (IE: Chest - 15)"
                  autosize
                  minRows={3}
                  {...getInputProps('measurements')}
                />
              </Stack>
            </CardSection>
          </Section>

          <CardSection p="md">
            <Group position="right">
              <Button variant="default" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" loading={isDirty() && isSubmitting}>
                Create
              </Button>
            </Group>
          </CardSection>
        </Stack>
      </form>
    </>
  )
}
