import {
  Modal as MantineModal,
  ModalProps as MantineModalProps,
  ScrollArea,
  Text,
} from '@mantine/core'

export type ModalProps = MantineModalProps & {
  hideClose?: boolean
}

export const Modal = (props: ModalProps): JSX.Element => {
  const {
    children,
    opened,
    title,
    onClose,
    hideClose,
    fullScreen = false,
  } = props

  return (
    <MantineModal.Root
      opened={opened}
      onClose={onClose}
      transitionProps={{ transition: 'rotate-left' }}
      scrollAreaComponent={ScrollArea.Autosize}
      fullScreen={fullScreen}
      centered
    >
      <MantineModal.Overlay color="gray.2" opacity={0.1} blur={3} />
      <MantineModal.Content>
        {title && (
          <MantineModal.Header>
            <MantineModal.Title>
              <Text fw="bold">{title}</Text>
            </MantineModal.Title>
            {!hideClose && <MantineModal.CloseButton />}
          </MantineModal.Header>
        )}
        <MantineModal.Body>{children}</MantineModal.Body>
      </MantineModal.Content>
    </MantineModal.Root>
  )
}
