import { useRouteError } from 'react-router-dom'

export const RouteNotFound = (): JSX.Element => {
  let msg
  // must use any react-router types hook returns as unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError()

  if (error.status === 404) {
    msg = 'Page not found!'
  }

  return <div>{msg}</div>
}
