import * as Yup from 'yup'

export const createBrandSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  website: Yup.string().required('Website is required'),
  description: Yup.string().required('Description is required'),
})

export type CreateBrandState = Yup.InferType<typeof createBrandSchema>

export const createBrandInitialState: () => CreateBrandState = () => ({
  name: '',
  website: '',
  description: '',
})
