import { postAdapter } from '@adapters/postAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { Post } from '@interfaces/entities/post/post'
import { apiSlice, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { EntityState } from '@reduxjs/toolkit'
import { postSerializer } from '@serializers/postSerializer'

export const postEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.POSTS],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPosts: builder.query<EntityState<Post>, string>({
        query: (sellerId: string) => ({
          url: `${EndpointPaths.POST}/${sellerId}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: Post[] }) =>
          postAdapter.setAll(
            postAdapter.getInitialState(),
            response?.data.map(postSerializer),
          ),
        providesTags: (result: EntityState<Post> | undefined) =>
          providesList(result?.ids, ApiTagTypes.POSTS),
      }),
    }),
  })

export const { useGetPostsQuery } = postEndpoints
