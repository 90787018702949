import { userAdapter } from '@adapters/userAdapter'
import { Section } from '@components/Section/Section'
import { User } from '@interfaces/entities/user/user'
import { RoutePaths } from '@interfaces/routePaths'
import {
  Anchor,
  Badge,
  Button,
  Card,
  CardSection,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { EntityState } from '@reduxjs/toolkit'
import {
  IconAt,
  IconCheck,
  IconIdBadge,
  IconShoppingBag,
  IconUserOff,
} from '@tabler/icons-react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

function UserTitle({ user }: { user: User }): JSX.Element {
  return (
    <>
      <Title order={5}>{user.name}</Title>
      <Button
        size="xs"
        leftIcon={
          user.isActive ? (
            <IconCheck size="0.9rem" />
          ) : (
            <IconUserOff size="0.9rem" />
          )
        }
        variant="light"
        color={user.isActive ? 'green' : 'gray'}
      >
        {user.isActive ? 'Active' : 'Inactive'}
      </Button>
    </>
  )
}

export const Details = () => {
  const { userId = '' } = useParams()
  const navigate = useNavigate()

  const {
    userEntityState,
    isLoading,
  }: { userEntityState: EntityState<User>; isLoading: boolean } =
    useOutletContext()

  const user =
    userEntityState &&
    userAdapter.getSelectors().selectById(userEntityState, userId)

  const handleSellerClick = (sellerId: string) =>
    navigate(
      `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_SHOPS}/${RoutePaths.SHOPS_PROFILE}/${sellerId}`,
    )

  return (
    <>
      {user ? (
        <>
          <Stack>
            <Section title={<UserTitle user={user} />}>
              <CardSection p="md" withBorder>
                <Stack>
                  <Group>
                    <Group spacing="xs">
                      <IconIdBadge size="1.5rem" />
                      <Title w={60} order={5} color="gray.7">
                        ID
                      </Title>
                    </Group>
                    <Text>{user.id}</Text>
                  </Group>
                  <Group>
                    <Group spacing="xs">
                      <IconAt size="1.5rem" />
                      <Title w={60} order={5} color="gray.7">
                        Email
                      </Title>
                    </Group>
                    <Text>{user.email}</Text>
                  </Group>
                </Stack>
              </CardSection>
            </Section>
          </Stack>

          {user.sellerUsers && (
            <>
              {user.sellerUsers.length > 0 ? (
                <Stack pt="sm">
                  <Section title="Shops">
                    <CardSection p="md" withBorder>
                      <Stack>
                        {user.sellerUsers.map((curr: User) => (
                          <Group spacing="xs" key={user.id}>
                            <IconShoppingBag size="1.5rem" />
                            <Anchor
                              onClick={() =>
                                handleSellerClick(curr?.seller?.id ?? '')
                              }
                            >
                              {curr?.seller?.name}
                            </Anchor>
                            <Badge color="pink" radius="sm" variant="light">
                              {curr.role.toLocaleLowerCase()}
                            </Badge>
                          </Group>
                        ))}
                      </Stack>
                    </CardSection>
                  </Section>
                </Stack>
              ) : null}
            </>
          )}
        </>
      ) : (
        <>{!isLoading && <Card withBorder>Unable to find user</Card>}</>
      )}
    </>
  )
}
