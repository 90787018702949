import { RoutePaths } from '@interfaces/routePaths'
import { selectIsLoggedIn } from '@redux/auth/authSlice'
import { store } from '@redux/store'
import { redirect } from 'react-router-dom'

export async function protectedGuard() {
  const isLoggedIn = selectIsLoggedIn(store.getState())

  if (!isLoggedIn) return redirect(RoutePaths.LOGIN)

  return null
}
