import { postAdapter } from '@adapters/postAdapter'
import { userAdapter } from '@adapters/userAdapter'
import { Content } from '@components/Content/Content'
import { EmptyState } from '@components/EmptyState/EmptyState'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { useSetSection } from '@hooks/useSetSection'
import { Post } from '@interfaces/entities/post/post'
import { User } from '@interfaces/entities/user/user'
import { MutationError } from '@interfaces/mutationError'
import {
  Box,
  Button,
  Card,
  CardSection,
  Group,
  Stack,
  Text,
  Textarea,
  Title,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useGetPostsQuery } from '@redux/post/postEndpoints'
import {
  useCreatePostMutation,
  useGetFollowersQuery,
} from '@redux/seller/sellerEndpoints'
import { selectActiveSeller } from '@redux/site/siteSlice'
import { EntityState } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  createPostInitialState,
  createPostSchema,
  CreatePostState,
} from '@schemas/seller/createPost'
import { useState } from 'react'

export const Base = (): JSX.Element => {
  useSetSection('Posts')
  const { id: sellerId } = useAppSelector(selectActiveSeller)
  const [formError, setFormError] = useState<MutationError | null>()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const { onSubmit, isDirty, getInputProps, reset, resetDirty } = useForm({
    initialValues: createPostInitialState(),
    validate: yupResolver(createPostSchema),
  })

  const { data } = useGetPostsQuery(sellerId ?? skipToken)
  const { data: followers } = useGetFollowersQuery(sellerId ?? skipToken)
  const [createPost] = useCreatePostMutation()

  const posts = data
    ? postAdapter.getSelectors().selectAll(data as EntityState<Post>)
    : []

  const followerCount = followers
    ? userAdapter.getSelectors().selectTotal(followers as EntityState<User>)
    : 0

  const handleSubmit = async (values: CreatePostState) => {
    if (isSubmitting) return

    const { body } = values

    setSubmitting(true)
    setFormError(null)

    try {
      await createPost({ sellerId, body }).unwrap()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
      reset()
      resetDirty()
    }
  }

  return (
    <Content>
      {posts && (
        <>
          <Box mb="md">
            <form
              onSubmit={onSubmit((values: CreatePostState) =>
                handleSubmit(values),
              )}
            >
              <Section title={<Title order={5}>New Post</Title>}>
                <CardSection p="md">
                  <Textarea
                    name="body"
                    maxRows={10}
                    minRows={6}
                    {...getInputProps('body')}
                  />
                </CardSection>
                <CardSection px="md" pb="md">
                  <Group position="right">
                    <Button
                      size="sm"
                      type="submit"
                      loading={isDirty() && isSubmitting}
                    >
                      Add Post
                    </Button>
                  </Group>
                </CardSection>
              </Section>
            </form>
          </Box>
          {posts.length > 0 ? (
            <Card withBorder>
              <CardSection p="sm" withBorder>
                <Group position="right">
                  <Text size="sm" c="dimmed">
                    Followers: {followerCount}
                  </Text>
                </Group>
              </CardSection>
              {posts.map((post: Post, i: number) => (
                <CardSection
                  key={`posts-${post.id}`}
                  p="md"
                  withBorder={i !== 0}
                >
                  <Stack spacing="xs">
                    <Text fw="bold" size="md" color="gray">
                      {post.body}
                    </Text>
                    <Text size="sm">
                      <Text c="violet" span>
                        {post.user.name}
                      </Text>{' '}
                      @ {post.createdAt}
                    </Text>
                  </Stack>
                </CardSection>
              ))}
            </Card>
          ) : (
            <>
              <EmptyState type="posts" />
            </>
          )}
        </>
      )}
    </Content>
  )
}
