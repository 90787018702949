export enum EndpointPaths {
  // Auth Endpoints
  AUTH_LOGIN = '/auth/login',
  AUTH_LOGIN_ADMIN = '/auth/login/admin',
  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_VERIFY_EMAIL = '/auth/confirm-registration',
  AUTH_RESET_PASSWORD = '/auth/reset-password',
  AUTH_RESEND_VERIFICATION_EMAIL = '/auth/resend-confirmation-code',
  AUTH_SIGNUP = '/auth/register',
  AUTH_CONNECT = '/auth/connect',
  AUTH_INVITE = '/auth/invite',

  // Account
  ACCOUNT_CHANGE_PASSWORD = '/auth/change-password',

  // Users
  SEARCH_USERS = '/user/search',
  LIST_USERS = '/user',

  // Seller(s)
  SELLER_ROOT = '/seller',
  SELLER_GET_ALL = '/seller/all',
  SELLER_ENABLE = '/seller/enable',
  SELLER_DISABLE = '/seller/disable',
  SELLER_REMOVE_USER = '/seller-user',

  // Brand
  BRAND = '/brand',
  BRANDS_ENABLE = '/brand/enable',
  BRANDS_DISABLE = '/brand/disable',

  // PRODUCT
  PRODUCT = '/product',
  PRODUCT_ENABLE = '/product/enable',
  PRODUCT_DISABLE = '/product/disable',

  // WISHLIST
  WISHLIST = '/wishlist/all',

  // POSTS
  POST = '/post',

  // FOLLOWERS
  FOLLOWERS = '/follower/seller',
}
