import { userAdapter } from '@adapters/userAdapter'
import { Seller } from '@interfaces/entities/seller/seller'
import { User } from '@interfaces/entities/user/user'
import type { RootState } from '@redux/store'
import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface SiteState {
  activeSection: string
  activeSeller: Seller | null
}

const initialState: SiteState = {
  activeSection: '',
  activeSeller: null,
}

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setActiveSection: (state, action: PayloadAction<{ section: string }>) => {
      state.activeSection = action.payload.section
    },
    setActiveSeller: (state, action: PayloadAction<{ seller: Seller }>) => {
      const { seller } = action.payload

      const sellerUsers = userAdapter
        .getSelectors()
        .selectAll(seller.users as EntityState<User>)

      const role = sellerUsers[0].sellerRole

      state.activeSeller = {
        ...seller,
        role,
      }
    },
    clearSiteInformation: (state: SiteState) => {
      state = initialState

      return state
    },
  },
})

export const { setActiveSection, setActiveSeller, clearSiteInformation } =
  siteSlice.actions

export const selectActiveSeller = (state: RootState) => state.site.activeSeller
export const selectActiveSection = (state: RootState) =>
  state.site.activeSection
