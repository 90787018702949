import { productAdapter } from '@adapters/productAdapter'
import { productAssetAdapter } from '@adapters/productAssetAdapter'
import { Section } from '@components/Section/Section'
import { Product } from '@interfaces/entities/product/product'
import { ProductAttributeTypes } from '@interfaces/entities/product/productAttributeTypes'
import { RoutePaths } from '@interfaces/routePaths'
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  CardSection,
  Grid,
  Group,
  Image,
  Modal,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { EntityState } from '@reduxjs/toolkit'
import {
  IconArrowsMaximize,
  IconBarcode,
  IconCategory,
  IconCheck,
  IconCircleDashed,
  IconColorFilter,
  IconCurrencyDollar,
  IconHanger,
  IconInfoSquareRounded,
  IconRuler2,
  IconRulerMeasure,
  IconShoppingBag,
  IconShoppingCartOff,
  IconSparkles,
  IconTrademark,
} from '@tabler/icons-react'
import { useState } from 'react'
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

function ProductTitle({ product }: { product: Product }): JSX.Element {
  return (
    <>
      <Title order={5}>{product.title}</Title>
      <Button
        size="xs"
        leftIcon={
          product.isActive ? (
            <IconCheck size="0.9rem" />
          ) : (
            <IconShoppingCartOff size="0.9rem" />
          )
        }
        variant="filled"
        color={product.isActive ? 'teal.5' : 'gray'}
      >
        {product.isActive ? 'Active' : 'Inactive'}
      </Button>
    </>
  )
}

export const Details = (): JSX.Element => {
  const { productId = '' } = useParams()
  const navigate = useNavigate()

  const {
    productEntityState,
    isLoading,
  }: { productEntityState: EntityState<Product>; isLoading: boolean } =
    useOutletContext()

  const product =
    productEntityState &&
    productAdapter.getSelectors().selectById(productEntityState, productId)

  const productAssets =
    product &&
    product.productAssets &&
    productAssetAdapter.getSelectors().selectAll(product.productAssets)

  const [fullImageView, { open, close }] = useDisclosure(false)
  const [currentMediaIndex, setCurrentMediaIndex] = useState<number | null>(
    null,
  )

  const handleOpenModal = (index: number) => {
    setCurrentMediaIndex(index)
    open()
  }

  const selectedImage =
    currentMediaIndex !== null ? productAssets?.[currentMediaIndex] : null
  const selectedImageUrl = selectedImage ? selectedImage.url : ''

  const handleSellerClick = () =>
    navigate(
      `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_SHOPS}/${RoutePaths.SHOPS_PROFILE}/${product?.seller?.id}`,
    )

  return (
    <>
      {product ? (
        <>
          <Section title={<ProductTitle product={product} />}>
            <CardSection p="md" withBorder>
              <Stack>
                <Grid>
                  <Grid.Col span={4}>
                    <Group spacing="xs">
                      <IconBarcode size="1.5rem" />
                      <Title order={5} color="gray.7">
                        SKU
                      </Title>
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Text>{product.sku}</Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={4}>
                    <Group spacing="xs">
                      <IconInfoSquareRounded size="1.5rem" />
                      <Title order={5} color="gray.7">
                        Description
                      </Title>
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Text style={{ wordWrap: 'break-word' }}>
                      {product.description}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Stack>
            </CardSection>
          </Section>

          <Stack pt="md">
            <Section title="Media">
              {productAssets && productAssets.length > 0 && (
                <CardSection p="sm">
                  <SimpleGrid cols={4} spacing="xl">
                    {productAssets.map((asset, index) => (
                      <Box key={asset.id}>
                        <Box
                          sx={() => ({
                            position: 'relative',
                            overflow: 'hidden',
                            '&:hover .action-icon': {
                              display: 'flex',
                            },
                          })}
                        >
                          <Image src={asset.url} sx={{}} />
                          <Tooltip
                            label="View Full Image"
                            color="dark"
                            withArrow
                            arrowSize={6}
                            sx={{
                              fontSize: '0.75rem',
                              padding: '4px 8px',
                            }}
                          >
                            <ActionIcon
                              className="action-icon"
                              color="dark"
                              variant="light"
                              size="md"
                              sx={{
                                display: 'none',
                                position: 'absolute',
                                bottom: '8px',
                                right: '8px',
                              }}
                              onClick={() => handleOpenModal(index)}
                            >
                              <IconArrowsMaximize size="1rem" />
                            </ActionIcon>
                          </Tooltip>
                        </Box>
                        <Text
                          size="xs"
                          color="gray"
                          mt="xs"
                          style={{ wordWrap: 'break-word' }}
                        >
                          {asset.caption}
                        </Text>
                      </Box>
                    ))}
                  </SimpleGrid>
                </CardSection>
              )}
              {selectedImageUrl && selectedImage && (
                <Modal
                  title={selectedImage.caption}
                  opened={fullImageView}
                  onClose={close}
                  size="auto"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    img: {
                      maxWidth: '100%',
                      maxHeight: 'calc(100vh - 40px)',
                    },
                  }}
                >
                  <Image src={selectedImageUrl} />
                </Modal>
              )}
            </Section>
          </Stack>
          <Stack pt="md">
            <Section title="Details">
              <CardSection p="md" withBorder>
                <Stack>
                  <Grid>
                    <Grid.Col span={4}>
                      <Group spacing="xs">
                        <IconShoppingBag size="1.5rem" />
                        <Title order={5} color="gray.7">
                          Seller
                        </Title>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Anchor onClick={handleSellerClick}>
                        {product?.seller?.name}
                      </Anchor>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Group spacing="xs">
                        <IconCurrencyDollar size="1.5rem" />
                        <Title order={5} color="gray.7">
                          Price
                        </Title>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Text>{product.price}</Text>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Group spacing="xs">
                        <IconTrademark size="1.5rem" />
                        <Title order={5} color="gray.7">
                          Brand
                        </Title>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Text>
                        {product.productBrand
                          ? product.productBrand.name
                          : product.brand}
                      </Text>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Group spacing="xs">
                        <IconHanger size="1.5rem" />
                        <Title order={5} color="gray.7">
                          Type
                        </Title>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Text>{product.type}</Text>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Group spacing="xs">
                        <IconCategory size="1.5rem" />
                        <Title order={5} color="gray.7">
                          Department
                        </Title>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Text>{product.department}</Text>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Group spacing="xs">
                        <IconRulerMeasure size="1.5rem" />
                        <Title order={5} color="gray.7">
                          Size
                        </Title>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Text>{product.size}</Text>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Group spacing="xs">
                        <IconColorFilter size="1.5rem" />
                        <Title order={5} color="gray.7">
                          Color
                        </Title>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Text>{product[ProductAttributeTypes.COLOR]}</Text>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Group spacing="xs">
                        <IconSparkles size="1.5rem" />
                        <Title order={5} color="gray.7">
                          Condition
                        </Title>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Text>{product.condition}</Text>
                    </Grid.Col>

                    {product[
                      ProductAttributeTypes.STAINS_AND_IMPERFECTIONS
                    ] && (
                      <>
                        <Grid.Col span={4}>
                          <Group spacing="xs">
                            <IconCircleDashed size="1.5rem" />
                            <Title order={5} color="gray.7">
                              Stains
                            </Title>
                          </Group>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <Text style={{ wordWrap: 'break-word' }}>
                            {
                              product[
                                ProductAttributeTypes.STAINS_AND_IMPERFECTIONS
                              ]
                            }
                          </Text>
                        </Grid.Col>
                      </>
                    )}

                    {product[ProductAttributeTypes.MEASUREMENTS] && (
                      <>
                        <Grid.Col span={4}>
                          <Group spacing="xs">
                            <IconRuler2 size="1.5rem" />
                            <Title order={5} color="gray.7">
                              Measurements
                            </Title>
                          </Group>
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <Text style={{ wordWrap: 'break-word' }}>
                            {product[ProductAttributeTypes.MEASUREMENTS]}
                          </Text>
                        </Grid.Col>
                      </>
                    )}
                  </Grid>
                </Stack>
              </CardSection>
            </Section>
          </Stack>
        </>
      ) : (
        <>{!isLoading && <Card withBorder>Unable to find product</Card>}</>
      )}

      <Outlet context={{ productEntityState, isLoading }} />
    </>
  )
}
