export enum ProductColors {
  WHITE = 'White',
  BLACK = 'Black',
  GRAY = 'Gray',
  IVORY = 'Ivory',
  TAN = 'Tan',
  BROWN = 'Brown',
  PURPLE = 'Purple',
  BLUE = 'Blue',
  TEAL = 'Teal',
  GREEN = 'Green',
  RED = 'Red',
  PINK = 'Pink',
  ORANGE = 'Orange',
  YELLOW = 'Yellow',
}
