import { Anchor, Card, Text } from '@mantine/core'
import { useNavigate } from 'react-router'

export interface EmptyStateProps {
  type: string
  createPath?: string
  isGeneric?: boolean
}

export const EmptyState = (props: EmptyStateProps): JSX.Element => {
  const navigate = useNavigate()
  const { type, createPath, isGeneric = false } = props
  const message = isGeneric
    ? `No ${type} found.`
    : `You don't have any ${type} yet.`

  return (
    <Card withBorder>
      <Text span>{message} </Text>
      {createPath && (
        <Anchor onClick={() => navigate(createPath)}>Create one now!</Anchor>
      )}
    </Card>
  )
}
