import * as Yup from 'yup'

export const resendVerificationEmailSchema = Yup.object({
  email: Yup.string().required('Email is required').email(),
})

export type ResendVerificationEmailState = Yup.InferType<
  typeof resendVerificationEmailSchema
>

export const resendVerificationEmailInitialState: () => ResendVerificationEmailState =
  () => ({
    email: '',
  })
