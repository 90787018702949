import { Modal } from '@components/Modal/Modal'
import { RoutePaths } from '@interfaces/routePaths'
import { Anchor, Stack, Text } from '@mantine/core'
import { useNavigate } from 'react-router-dom'

export interface ConnectSuccessProps {
  seller: string
}

export const ConnectSuccess = ({
  seller,
}: ConnectSuccessProps): JSX.Element => {
  const navigate = useNavigate()

  const onCloseHandler = () => navigate(RoutePaths.DASHBOARD)

  return (
    <Modal
      title="Success"
      opened={true}
      onClose={onCloseHandler}
      autoFocus={false}
      hideClose
    >
      <Stack>
        <Text data-autoFocus>
          Your account is now connected to{' '}
          <Text color="pink" span>
            {seller}
          </Text>
        </Text>
        <Anchor href={RoutePaths.DASHBOARD}>Proceed to Dashboard</Anchor>
      </Stack>
    </Modal>
  )
}
