import { setActiveSection } from '@redux/site/siteSlice'
import { useEffect } from 'react'
import { useAppDispatch } from './redux'

export function useSetSection(section: string) {
  const dispatch = useAppDispatch()

  const setSection = () => {
    dispatch(setActiveSection({ section }))
    document.title = `NabThis - ${section}`
  }

  useEffect(() => {
    setSection()
  }, [])

  return setSection
}
