import { Box, Button, Flex, Group, Modal, ScrollArea } from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react'
import { ReactNode } from 'react'

export type DestructiveConfirmProps = {
  children: ReactNode | ReactNode[]
  opened: boolean
  confirmText?: string
  isLoading?: boolean
  onCancel?: () => void
  onConfirm?: () => void
}
export const DestructiveConfirm = (props: DestructiveConfirmProps) => {
  const {
    children,
    opened,
    isLoading = false,
    confirmText = 'Confirm',
    onCancel = () => {},
    onConfirm = () => {},
  } = props

  return (
    <Modal.Root
      opened={opened}
      onClose={onCancel}
      transitionProps={{ transition: 'rotate-left' }}
      scrollAreaComponent={ScrollArea.Autosize}
      centered
    >
      <Modal.Overlay color="gray.2" opacity={0.1} blur={3} />
      <Modal.Content>
        <Modal.Body>
          <Flex align="flex-start">
            <Box
              mr="sm"
              mt={2}
              sx={(theme) => ({ color: theme.colors.red[5] })}
            >
              <IconAlertTriangle size="1.2rem" />
            </Box>
            {children}
          </Flex>
          <Group position="right" mt="md">
            <Button variant="default" size="sm" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              size="sm"
              loading={isLoading}
              onClick={onConfirm}
              color="red"
            >
              {confirmText}
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
