import { brandAdapter } from '@adapters/brandAdapter'
import { DestructiveConfirm } from '@components/DestructiveConfirm/DestructiveConfirm'
import { Section } from '@components/Section/Section'
import { Brand } from '@interfaces/entities/brand/brand'
import { RoutePaths } from '@interfaces/routePaths'
import {
  Anchor,
  Button,
  Card,
  CardSection,
  Flex,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useSetBrandStatusMutation } from '@redux/brand/brandEndpoints'
import { EntityState } from '@reduxjs/toolkit'
import {
  IconCheck,
  IconEdit,
  IconExternalLink,
  IconInfoSmall,
  IconShoppingCartOff,
  IconTrash,
} from '@tabler/icons-react'
import { buildLink } from '@utils/buildLink'
import { useState } from 'react'
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

function BrandTitle({
  brand,
  handleStatusClick,
}: {
  brand: Brand
  handleStatusClick: () => void
}): JSX.Element {
  return (
    <>
      <Title order={5}>{brand.name}</Title>
      <Button
        size="xs"
        leftIcon={
          brand.isActive ? (
            <IconCheck size="0.9rem" />
          ) : (
            <IconShoppingCartOff size="0.9rem" />
          )
        }
        onClick={handleStatusClick}
        variant="light"
        color={brand.isActive ? 'green' : 'gray'}
      >
        {brand.isActive ? 'Active' : 'Inactive'}
      </Button>
    </>
  )
}
export const Details = (): JSX.Element => {
  const { brandId = '' } = useParams()
  const navigate = useNavigate()

  const {
    brandEntityState,
    isLoading,
  }: {
    brandEntityState: EntityState<Brand>
    isLoading: boolean
    error: unknown
  } = useOutletContext()
  const [isStatusOpen, setIsStatusOpen] = useState<boolean>(false)
  const [setBrandStatus, { isLoading: brandStatusIsLoading }] =
    useSetBrandStatusMutation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const brand =
    brandEntityState &&
    brandAdapter.getSelectors().selectById(brandEntityState, brandId)

  const handleManageNavigate = (mode: 'edit' | 'delete') =>
    navigate(`${RoutePaths.ADMIN_BRANDS_MANAGE}/${mode}`)

  const handleEditClick = () => handleManageNavigate('edit')
  const handleDeleteClick = () => handleManageNavigate('delete')
  const handleStatusChange = () => setIsStatusOpen(!isStatusOpen)

  const handleSetStatus = async () => {
    if (isSubmitting || !brand) return

    setIsSubmitting(true)

    try {
      const isActive = brand && brand.isActive
      await setBrandStatus({ brandId, isActive }).unwrap()

      showNotification({
        message: `${brand.name} status updated!`,
        color: 'green',
      })
    } catch (e) {
      showNotification({
        message: `Unable to update ${brand.name} status!`,
        color: 'red',
      })
    } finally {
      setIsSubmitting(false)
      setIsStatusOpen(false)
    }
  }

  return (
    <>
      {brand ? (
        <>
          <Section
            title={
              <BrandTitle
                brand={brand}
                handleStatusClick={handleStatusChange}
              />
            }
          >
            <CardSection
              p="xs"
              sx={(theme) => ({
                backgroundColor: theme.fn.lighten(theme.colors.gray[0], 0.3),
              })}
            >
              <Group position="right">
                <Group spacing="xs">
                  <Button
                    leftIcon={<IconEdit size="0.9rem" />}
                    onClick={handleEditClick}
                  >
                    Edit
                  </Button>
                  <Button
                    color="red"
                    leftIcon={<IconTrash size="0.9rem" />}
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button>
                </Group>
              </Group>
            </CardSection>
            <CardSection p="md" withBorder>
              <Flex>
                <div>
                  <IconInfoSmall size="1.8rem" />
                </div>
                <Text ml="sm" color="gray.7" my={5} italic span>
                  {brand.description}
                </Text>
              </Flex>
            </CardSection>
            <CardSection p="md" withBorder>
              <Stack>
                <Group>
                  <Group spacing="xs">
                    <IconExternalLink size="1.5rem" />
                    <Title w={75} order={5} color="gray.7">
                      Website
                    </Title>
                  </Group>
                  <Anchor href={buildLink(brand.website)} target="_blank">
                    {brand.website}
                  </Anchor>
                </Group>
              </Stack>
            </CardSection>
          </Section>
          <DestructiveConfirm
            confirmText={brand.isActive ? 'Set Inactive' : 'Set Active'}
            opened={isStatusOpen}
            onCancel={handleStatusChange}
            onConfirm={handleSetStatus}
            isLoading={brandStatusIsLoading}
          >
            <Text>
              Are you sure you would like to mark this brand as{' '}
              <strong>{brand?.isActive ? 'Inactive' : 'Active'}</strong>?
            </Text>
          </DestructiveConfirm>
        </>
      ) : (
        <>{!isLoading && <Card withBorder>Unable to find brand</Card>}</>
      )}

      <Outlet context={{ brandEntityState, isLoading }} />
    </>
  )
}
