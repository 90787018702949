import { wishlistAdapter } from '@adapters/wishlistAdapter'
import { Content } from '@components/Content/Content'
import { EmptyState } from '@components/EmptyState/EmptyState'
import { Section } from '@components/Section/Section'
import { useSetSection } from '@hooks/useSetSection'
import { Wishlist } from '@interfaces/entities/wishlist/wishlist'
import { CardSection, Group, Stack, Text, Title } from '@mantine/core'
import { useGetWishlistsQuery } from '@redux/wishlist/wishlistEndpoints'
import { EntityState } from '@reduxjs/toolkit'
import { Outlet } from 'react-router-dom'

export const Base = (): JSX.Element => {
  useSetSection('Wishlists')

  const { data } = useGetWishlistsQuery()

  const wishlists = data
    ? wishlistAdapter.getSelectors().selectAll(data as EntityState<Wishlist>)
    : []

  return (
    <Content>
      {wishlists && (
        <>
          {wishlists.length > 0 ? (
            <Section title={<Title order={5}>All Wishlists</Title>}>
              {wishlists.map((wishlist: Wishlist, i: number) => (
                <CardSection
                  key={wishlist.id}
                  p="md"
                  withBorder={i !== 0}
                  sx={(theme) => ({
                    cursor: 'pointer',
                  })}
                >
                  <Stack>
                    <Text fw="bold" size="sm" color="violet">
                      {wishlist.label}
                    </Text>
                    <Group>
                      <Text size="xs">Brand: {wishlist.preferences.brand}</Text>
                      <Text size="xs">
                        Department: {wishlist.preferences.department}
                      </Text>
                      <Text size="xs">Type: {wishlist.preferences.type}</Text>
                      <Text size="xs">Color: {wishlist.preferences.color}</Text>
                      <Text size="xs">Size: {wishlist.preferences.size}</Text>
                      <Text size="xs">
                        Condition: {wishlist.preferences.condition}
                      </Text>
                    </Group>
                  </Stack>
                </CardSection>
              ))}
            </Section>
          ) : (
            <>
              <EmptyState type="wishlists" />
            </>
          )}
        </>
      )}
    </Content>
  )
}
