import * as Yup from 'yup'

export const inviteExistingSchema = Yup.object({
  searchTerm: Yup.string().optional(),
  userId: Yup.string().required('You must select a user'),
  email: Yup.string().required('You must select a user'),
  role: Yup.string().required('Role is required'),
})

export type InviteExistingState = Yup.InferType<typeof inviteExistingSchema>

export const inviteExistingInitialState: () => InviteExistingState = () => ({
  searchTerm: '',
  userId: '',
  email: '',
  role: '',
})
