import { FormError } from '@components/FormError/FormError'
import { SplitPanel } from '@components/SplitPanel/SplitPanel'
import { MutationError } from '@interfaces/mutationError'
import { RoutePaths } from '@interfaces/routePaths'
import { Button, Stack, Text, TextInput, Title } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useResendVerificationEmailMutation } from '@redux/auth/authEndpoints'
import {
  resendVerificationEmailInitialState,
  resendVerificationEmailSchema,
  ResendVerificationEmailState,
} from '@schemas/auth/resendVerifciationEmail'
import { useState } from 'react'

export const ResendVerificationEmail = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()

  const [resendVerificationEmail, { isSuccess }] =
    useResendVerificationEmailMutation()

  const { onSubmit, isDirty, getInputProps } = useForm({
    initialValues: resendVerificationEmailInitialState(),
    validate: yupResolver(resendVerificationEmailSchema),
  })

  const handleSubmit = async (values: ResendVerificationEmailState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      await resendVerificationEmail(values).unwrap()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <SplitPanel
      title="Email Verification"
      subtitle="Enter the email address your account is associated with."
      hideTitles={isSuccess}
      backLink={RoutePaths.LOGIN}
      backText="sign in"
    >
      {isSuccess ? (
        <Stack>
          <Title order={3}>Email verification link sent</Title>
          <Text>
            We've sent an email verification link to your email. Please check
            your inbox. If you cannot find this email check your spam folder or
            refresh this page to try again.
          </Text>
        </Stack>
      ) : (
        <form
          onSubmit={onSubmit((values: ResendVerificationEmailState) =>
            handleSubmit(values),
          )}
        >
          <Stack>
            {!isSuccess && formError && <FormError error={formError} />}
            <TextInput
              type="email"
              placeholder="Email"
              {...getInputProps('email')}
            />
            <Button type="submit" loading={isDirty() && isSubmitting}>
              Send New Verification Link
            </Button>
          </Stack>
        </form>
      )}
    </SplitPanel>
  )
}
