import { clearAccountInformation } from '@redux/account/accountSlice'
import { logOut } from '@redux/auth/authSlice'
import { clearSiteInformation } from '@redux/site/siteSlice'
import { persistor, store } from '@redux/store'

export function clearUserState() {
  store.dispatch(logOut())
  store.dispatch(clearAccountInformation())
  store.dispatch(clearSiteInformation())
  persistor.purge()
}
