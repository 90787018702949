import { sellerAdapter } from '@adapters/sellerAdapter'
import { userAdapter } from '@adapters/userAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { FollowerResponse } from '@interfaces/entities/follower/followerResponse'
import { Seller } from '@interfaces/entities/seller/seller'
import { SellerResponse } from '@interfaces/entities/seller/sellerResponse'
import { User } from '@interfaces/entities/user/user'
import { apiSlice, invalidatesList, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { setActiveSeller } from '@redux/site/siteSlice'
import { store } from '@redux/store'
import { EntityState } from '@reduxjs/toolkit'
import { followerSerializer } from '@serializers/followerSerializer'
import { sellerSerializer } from '@serializers/sellerSerializer'

export const sellerEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      ApiTagTypes.SELLERS_ALL,
      ApiTagTypes.SELLERS_USER,
      ApiTagTypes.FOLLOWERS,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllSellers: builder.query<EntityState<Seller>, void>({
        query: () => ({
          url: EndpointPaths.SELLER_GET_ALL,
          method: 'GET',
        }),
        transformResponse: (response: { data: SellerResponse[] }) =>
          sellerAdapter.setAll(
            sellerAdapter.getInitialState(),
            response?.data.map(sellerSerializer),
          ),
        providesTags: (result: EntityState<Seller> | undefined) =>
          providesList(result?.ids, ApiTagTypes.SELLERS_ALL),
      }),
      getUserSellers: builder.query<EntityState<Seller>, void>({
        query: () => ({
          url: EndpointPaths.SELLER_ROOT,
          method: 'GET',
        }),
        transformResponse: (response: { data: SellerResponse[] }) => {
          const dispatch = store.dispatch
          const state = store.getState()

          const sellers = sellerAdapter.setAll(
            sellerAdapter.getInitialState(),
            response?.data.map(sellerSerializer),
          )

          if (response.data.length > 0 && !state.account.activeSeller?.id) {
            const firstId = sellers.ids[0]
            const seller = sellerAdapter
              .getSelectors()
              .selectById(sellers, firstId)
            seller && dispatch(setActiveSeller({ seller: seller as Seller }))
          }

          return sellers
        },
        providesTags: (result: EntityState<Seller> | undefined) =>
          providesList(result?.ids, ApiTagTypes.SELLERS_USER),
      }),
      getFollowers: builder.query<EntityState<User>, string>({
        query: (sellerId: string) => ({
          url: `${EndpointPaths.FOLLOWERS}/${sellerId}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: FollowerResponse[] }) =>
          userAdapter.setAll(
            userAdapter.getInitialState(),
            response?.data.map(followerSerializer),
          ),
        providesTags: (result: EntityState<User> | undefined) =>
          providesList(result?.ids, ApiTagTypes.FOLLOWERS),
      }),
      createSeller: builder.mutation({
        query: (body) => ({
          url: EndpointPaths.SELLER_ROOT,
          method: 'POST',
          body: { ...body },
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SELLERS_ALL),
          invalidatesList(ApiTagTypes.SELLERS_USER),
        ],
      }),
      editSeller: builder.mutation({
        query: ({ sellerId, ...body }) => ({
          url: `${EndpointPaths.SELLER_ROOT}/${sellerId}`,
          method: 'PATCH',
          body: { ...body },
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SELLERS_ALL),
          invalidatesList(ApiTagTypes.SELLERS_USER),
        ],
      }),
      deleteSeller: builder.mutation({
        query: (sellerId) => ({
          url: `${EndpointPaths.SELLER_ROOT}/${sellerId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SELLERS_ALL),
          invalidatesList(ApiTagTypes.SELLERS_USER),
        ],
      }),
      setStatus: builder.mutation({
        query: ({ sellerId, isActive }) => ({
          url: isActive
            ? `${EndpointPaths.SELLER_DISABLE}/${sellerId}`
            : `${EndpointPaths.SELLER_ENABLE}/${sellerId}`,
          method: 'POST',
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SELLERS_ALL),
          invalidatesList(ApiTagTypes.SELLERS_USER),
        ],
      }),
      removeSellerUser: builder.mutation({
        query: (sellerUserId) => ({
          url: `${EndpointPaths.SELLER_REMOVE_USER}/${sellerUserId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SELLERS_ALL),
          invalidatesList(ApiTagTypes.SELLERS_USER),
        ],
      }),
      createPost: builder.mutation({
        query: ({ sellerId, body }: { sellerId: string; body: string }) => ({
          url: `${EndpointPaths.POST}/${sellerId}`,
          method: 'POST',
          body: { body },
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.POSTS)],
      }),
    }),
  })

export const {
  useGetAllSellersQuery,
  useGetUserSellersQuery,
  useGetFollowersQuery,
  useCreateSellerMutation,
  useSetStatusMutation,
  useRemoveSellerUserMutation,
  useEditSellerMutation,
  useDeleteSellerMutation,
  useCreatePostMutation,
} = sellerEndpoints
