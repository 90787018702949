import { useAppSelector } from '@hooks/redux'
import { ProductCategory } from '@interfaces/entities/product/productCategory'
import { useGetProductsQuery } from '@redux/product/productEndpoints'
import { selectActiveSeller } from '@redux/site/siteSlice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Outlet } from 'react-router'
import { List } from './List'

export const Thrift = (): JSX.Element => {
  const seller = useAppSelector(selectActiveSeller)
  const { data: productEntityState, isLoading } = useGetProductsQuery(
    seller && seller.id
      ? {
          sellerId: seller.id,
          category: ProductCategory.THRIFT,
        }
      : skipToken,
  )

  return (
    <>
      <List
        category={ProductCategory.THRIFT}
        productEntityState={productEntityState}
        isLoading={isLoading}
      />

      <Outlet
        context={{
          productEntityState,
          isLoading,
          category: ProductCategory.THRIFT,
        }}
      />
    </>
  )
}
