import { RoutePaths } from '@interfaces/routePaths'
import { Connect } from '@views/Auth/Connect'
import { ForgotPassword } from '@views/Auth/ForgotPassword'
import { Login } from '@views/Auth/Login'
import { ResendVerificationEmail } from '@views/Auth/ResendVerificationEmail'
import { ResetPassword } from '@views/Auth/ResetPassword'
import { Signup } from '@views/Auth/Signup'
import { VerifyEmail } from '@views/Auth/VerifyEmail'
import { RouteObject } from 'react-router-dom'
import { protectedGuard } from '../loaders/guards/protected'
import { publicGuard } from '../loaders/guards/public'
import { logoutLoader } from '../loaders/logout'

export const AUTH_PATHS: RouteObject[] = [
  {
    path: RoutePaths.LOGIN,
    element: <Login />,
    loader: publicGuard,
  },
  {
    path: RoutePaths.SIGNUP,
    element: <Signup />,
    loader: publicGuard,
  },
  {
    path: RoutePaths.CONNECT,
    element: <Connect />,
    loader: protectedGuard,
  },
  {
    path: RoutePaths.FORGOT_PASSWORD,
    element: <ForgotPassword />,
    loader: publicGuard,
  },
  {
    path: RoutePaths.VERIFY_EMAIL,
    element: <VerifyEmail />,
  },
  {
    path: RoutePaths.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: RoutePaths.RESEND_VERIFICATION_EMAIL,
    element: <ResendVerificationEmail />,
  },
  {
    path: RoutePaths.LOGOUT,
    loader: logoutLoader,
  },
]
