import * as Yup from 'yup'

export const createPostSchema = Yup.object({
  body: Yup.string().required('Post content is required'),
})

export type CreatePostState = Yup.InferType<typeof createPostSchema>

export const createPostInitialState: () => CreatePostState = () => ({
  body: '',
})
