import { sellerAdapter } from '@adapters/sellerAdapter'
import { DestructiveConfirm } from '@components/DestructiveConfirm/DestructiveConfirm'
import { useAppSelector } from '@hooks/redux'
import { Seller } from '@interfaces/entities/seller/seller'
import { RoutePaths } from '@interfaces/routePaths'
import { Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useDeleteSellerMutation } from '@redux/seller/sellerEndpoints'
import { selectShops } from '@redux/seller/sellerSlice'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const DeleteSeller = (): JSX.Element => {
  const navigate = useNavigate()
  const { sellerId = '' } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [deleteSeller] = useDeleteSellerMutation()
  const sellerEntityState = useAppSelector(selectShops)

  const seller =
    sellerEntityState &&
    (sellerAdapter
      .getSelectors()
      .selectById(sellerEntityState, sellerId) as Seller)

  const handleClose = () => navigate(`../../${RoutePaths.SHOPS_LIST}`)

  const handleDelete = async () => {
    if (isSubmitting || !sellerId) return

    setSubmitting(true)

    try {
      await deleteSeller(sellerId).unwrap()

      showNotification({
        message: `${seller.name} deleted!`,
        color: 'green',
      })
    } catch (err) {
      showNotification({
        message: `Unable to delete ${seller.name}!`,
        color: 'red',
      })
    } finally {
      handleClose()
    }
  }

  return (
    <DestructiveConfirm
      confirmText="Delete"
      opened={true}
      onCancel={handleClose}
      onConfirm={handleDelete}
      isLoading={isSubmitting}
    >
      <Text>
        Are you sure you would like to delete the seller{' '}
        <strong>{seller?.name}</strong>?
      </Text>
    </DestructiveConfirm>
  )
}
