import { useParams } from 'react-router-dom'
import { CreateBrand } from './CreateBrand'
import { DeleteBrand } from './DeleteBrand'
import { EditBrand } from './EditBrand'

export const Manage = (): JSX.Element => {
  const { mode } = useParams()

  return (
    <>
      {!mode && <CreateBrand />}
      {mode === 'edit' && <EditBrand />}
      {mode === 'delete' && <DeleteBrand />}
    </>
  )
}
