import { Tabs } from '@mantine/core'
import { useId } from '@mantine/hooks'
import { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export interface TabNavigationItem {
  label: string | JSX.Element
  path: string
  icon?: ReactNode
  index?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}

export interface TabNavigationProps extends React.HTMLAttributes<HTMLElement> {
  tabs: TabNavigationItem[]
}

export const TabNavigation = (props: TabNavigationProps) => {
  const { tabs } = props

  const id = useId()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleTabChange = (value: string) => navigate(value)

  const activeMatch = (tab: TabNavigationItem) => {
    return tab?.index ? tab.path === pathname : pathname.includes(tab.path)
  }

  return (
    <Tabs
      value={pathname}
      onTabChange={handleTabChange}
      keepMounted={false}
      unstyled
      styles={(theme) => ({
        tab: {
          ...theme.fn.focusStyles(),
          color: theme.colors.gray[7],
          fontFamily: theme.fontFamily,
          backgroundColor: theme.white,
          border: 'none',
          borderBottom: 'solid 2px transparent',
          padding: `0 ${theme.spacing.sm}`,
          cursor: 'pointer',
          fontSize: theme.fontSizes.md,
          display: 'flex',
          alignItems: 'center',
          marginLeft: `${theme.spacing.sm}`,

          '&[data-active]': {
            borderBottom: `solid 2px ${theme.colors.violet[5]}`,
            color: theme.colors.violet[5],
          },
          '&[data-active-match="true"]': {
            borderBottom: `solid 2px ${theme.colors.violet[5]}`,
            color: theme.colors.violet[5],
          },
          '&[data-active]:hover': {
            borderBottom: `solid 2px ${theme.colors.violet[5]}`,
          },
          '&[data-active-match="true"]:hover': {
            borderBottom: `solid 2px ${theme.colors.violet[5]}`,
          },
          '&:hover': {
            borderBottom: `solid 2px ${theme.colors.gray[4]}`,
          },
        },
        tabsList: {
          width: '100%',
          height: 50,
          minHeight: 50,
          display: 'flex',
          borderBottom: `solid 1px ${theme.colors.gray[2]}`,
          backgroundColor: theme.white,
        },
        tabIcon: {
          marginRight: '6px',
          display: 'flex',
          alignItems: 'center',
        },
      })}
    >
      <Tabs.List>
        {tabs.map((tab: TabNavigationItem, index: number) => {
          return (
            <Tabs.Tab
              icon={tab.icon ?? undefined}
              key={`${id}-list-${index}`}
              value={tab.path}
              data-active-match={activeMatch(tab)}
            >
              {tab.label}
            </Tabs.Tab>
          )
        })}
      </Tabs.List>
    </Tabs>
  )
}
