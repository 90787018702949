import { ProductCategory } from '@interfaces/entities/product/productCategory'
import { useParams, useSearchParams } from 'react-router-dom'
import { CreateThriftProduct } from './CreateThriftProduct'
import { CreateVintageProduct } from './CreateVintageProduct'
import { DeleteProduct } from './DeleteProduct'
import { EditThriftProduct } from './EditThriftProduct'
import { EditVintageProduct } from './EditVintageProduct'

export const Manage = (): JSX.Element => {
  const { mode } = useParams()
  const [searchParams] = useSearchParams()
  const category = searchParams.get('category') ?? ProductCategory.THRIFT
  const CreateComponent =
    category === ProductCategory.THRIFT
      ? CreateThriftProduct
      : CreateVintageProduct

  const EditComponent =
    category === ProductCategory.THRIFT ? EditThriftProduct : EditVintageProduct

  return (
    <>
      {!mode && <CreateComponent />}
      {mode === 'edit' && <EditComponent />}
      {mode === 'delete' && <DeleteProduct />}
    </>
  )
}
