import { Post } from '@interfaces/entities/post/post'
import moment from 'moment'

export function postSerializer(post: Post): Post {
  const { createdAt, ...rest } = post

  return {
    ...rest,
    createdAt: moment(createdAt).format('MMM DD YYYY, h:mma'), // November 23rd 2016, 12:03:36 pm
  }
}
