import { TabNavigationItem } from '@components/TabNavigation/TabNavigation'
import { RoutePaths } from '@interfaces/routePaths'

export const ACCOUNT_TABS: TabNavigationItem[] = [
  {
    label: 'Details',
    path: `${RoutePaths.ACCOUNT}/${RoutePaths.ACCOUNT_DETAILS}`,
    index: true,
  },
  {
    label: 'Change Password',
    path: `${RoutePaths.ACCOUNT}/${RoutePaths.ACCOUNT_CHANGE_PASSWORD}`,
  },
]
