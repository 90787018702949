import type { RootState } from '@redux/store'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface AuthState {
  isLoggedIn: boolean
  accessToken: string | undefined
  refreshToken: string | undefined
}

const initialState: AuthState = {
  isLoggedIn: false,
  accessToken: '',
  refreshToken: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state: AuthState, action: PayloadAction<AuthState>) => {
      const { isLoggedIn, accessToken, refreshToken } = action.payload
      state.isLoggedIn = isLoggedIn
      state.accessToken = accessToken
      state.refreshToken = refreshToken
    },
    setAccessToken: (
      state: AuthState,
      action: PayloadAction<Partial<AuthState>>,
    ) => {
      state.accessToken = action.payload?.accessToken
    },
    logOut: (state: AuthState) => {
      state.isLoggedIn = false
      state.accessToken = ''
      state.refreshToken = ''
    },
  },
})

export const { setCredentials, setAccessToken, logOut } = authSlice.actions
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn
export const selectAccessToken = (state: RootState) => state.auth.accessToken
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken
