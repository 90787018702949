import {
  Container,
  ContainerProps,
  CSSObject,
  MantineTheme,
} from '@mantine/core'

export type BlockProps = ContainerProps & {
  sx?: (theme: MantineTheme) => CSSObject
}

// Block is a mantine container without the odd width/maxwidth defaults...
export const Block = (props: BlockProps): JSX.Element => {
  const {
    w = '100%',
    maw = '100%',
    p = 0,
    m = 0,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sx = (theme: MantineTheme) => ({}),
    children,
    ...rest
  } = props

  return (
    <Container
      p={p}
      m={m}
      sx={(theme) => ({
        width: `${w}`,
        maxWidth: `${maw}`,
        ...sx(theme),
      })}
      {...rest}
    >
      {children}
    </Container>
  )
}
