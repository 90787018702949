import * as Yup from 'yup'

export const editBrandSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  website: Yup.string().required('Website is required'),
  description: Yup.string().required('Description is required'),
})

export type EditBrandState = Yup.InferType<typeof editBrandSchema>

export const editBrandInitialState: (
  defaults: Partial<EditBrandState>,
) => EditBrandState = (defaults: Partial<EditBrandState>) => {
  return {
    name: defaults?.name ?? '',
    website: defaults?.website ?? '',
    description: defaults?.description ?? '',
  }
}
