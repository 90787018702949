import { LINKS } from '@constants/links'
import { useAppSelector } from '@hooks/redux'
import { Box } from '@mantine/core'
import { selectAccountInformation } from '@redux/account/accountSlice'
import { isAdmin } from '@utils/isAdmin'
import { NavLinksGroup } from './NavLinksGroup'

export function NavLinks() {
  const user = useAppSelector(selectAccountInformation)

  const links = LINKS.map((item) => {
    const shouldShowLink = item.isAdmin && isAdmin(user.role)

    return item.isAdmin ? (
      shouldShowLink && <NavLinksGroup {...item} key={item.label} />
    ) : (
      <NavLinksGroup {...item} key={item.label} />
    )
  })

  return <Box pb="sm">{links}</Box>
}
