import * as Yup from 'yup'

export const forgotPasswordSchema = Yup.object({
  email: Yup.string().required('Email is required').email(),
})

export type ForgotPasswordState = Yup.InferType<typeof forgotPasswordSchema>

export const forgotPasswordInitialState: () => ForgotPasswordState = () => ({
  email: '',
})
