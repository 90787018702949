import { Content } from '@components/Content/Content'
import { useSetSection } from '@hooks/useSetSection'
import { useGetAllUsersQuery } from '@redux/user/userEndpoints'
import { Outlet } from 'react-router-dom'

export const Base = (): JSX.Element => {
  useSetSection('Users')

  const { data: userEntityState, isLoading, error } = useGetAllUsersQuery()

  return (
    <Content>
      <Outlet context={{ userEntityState, isLoading, error }} />
    </Content>
  )
}
