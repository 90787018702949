import {
  Box,
  Collapse,
  createStyles,
  Group,
  rem,
  Text,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export interface NavLinksGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: React.FC<any>
  label: string
  initiallyOpened?: boolean
  color: string
  link?: string
  match?: string
  links?: { label: string; link: string; match?: string }[]
}

const useStyles = createStyles(() => ({
  active: {
    color: 'red',
  },
  chevron: {
    transition: 'transform 200ms ease',
  },
}))

export const NavLinksGroup = (props: NavLinksGroupProps): JSX.Element => {
  const {
    icon: Icon,
    label,
    color,
    initiallyOpened = true,
    link,
    match,
    links,
  } = props

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { classes, cx } = useStyles()
  const hasLinks = Array.isArray(links)
  const [opened, setOpened] = useState(initiallyOpened || false)

  const isActive = !hasLinks && match && pathname.includes(match)

  const items = (hasLinks ? links : []).map((link) => {
    const linkIsActive = link.match
      ? pathname.includes(link.match)
      : pathname.includes(link.link)

    return (
      <Text
        className={cx({ [classes.active]: isActive })}
        sx={(theme) => ({
          fontWeight: 500,
          display: 'block',
          textDecoration: 'none',
          cursor: 'pointer',
          padding: `${theme.spacing.xs} ${theme.spacing.md}`,
          paddingLeft: rem(31),
          marginLeft: rem(30),
          fontSize: theme.fontSizes.sm,
          color: theme.colors.gray[7],
          borderLeft: `${rem(1)} solid ${theme.colors.gray[3]}`,
          backgroundColor: linkIsActive ? theme.colors.gray[1] : 'transparent',

          '&:hover': {
            backgroundColor: linkIsActive
              ? theme.colors.gray[1]
              : theme.colors.gray[0],
            color: theme.black,
          },
        })}
        onClick={() => navigate(link.link, { preventScrollReset: true })}
        key={link.label}
      >
        {link.label}
      </Text>
    )
  })

  const handleClick = () => {
    if (link) return navigate(link, { preventScrollReset: true })

    setOpened((o) => !o)
  }

  return (
    <>
      <UnstyledButton
        onClick={handleClick}
        sx={(theme) => ({
          fontWeight: 500,
          display: 'block',
          width: '100%',
          padding: `${theme.spacing.xs} ${theme.spacing.md}`,
          color: theme.black,
          fontSize: theme.fontSizes.sm,
          backgroundColor:
            !hasLinks && isActive ? theme.colors.gray[1] : 'transparent',

          '&:hover': {
            backgroundColor:
              !hasLinks && isActive
                ? theme.colors.gray[1]
                : theme.colors.gray[0],
            color: theme.black,
          },
        })}
      >
        <Group position="apart" spacing={0}>
          <Group spacing="md" align="center">
            <ThemeIcon variant="light" color={color} size={30}>
              <Icon size="1.1rem" />
            </ThemeIcon>
            <Box>{label}</Box>
          </Group>
          {hasLinks && (
            <IconChevronRight
              className={classes.chevron}
              size="1rem"
              stroke={1.5}
              style={{
                transform: opened ? 'rotate(90deg)' : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  )
}
