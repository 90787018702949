import { RoutePaths } from '@interfaces/routePaths'
import { Base } from '@views/Posts/Base'
import { RouteObject } from 'react-router-dom'

export const POSTS_PATHS: RouteObject[] = [
  {
    path: RoutePaths.POSTS,
    element: <Base />,
  },
]
