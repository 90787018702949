import { wishlistAdapter } from '@adapters/wishlistAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { Wishlist } from '@interfaces/entities/wishlist/wishlist'
import { WishlistResponse } from '@interfaces/entities/wishlist/wishlistResponse'
import { apiSlice, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { EntityState } from '@reduxjs/toolkit'
import { wishlistSerializer } from '@serializers/wishlistSerializer'

export const wishlistEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.WISHLIST],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWishlists: builder.query<EntityState<Wishlist>, void>({
        query: () => ({
          url: `${EndpointPaths.WISHLIST}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: WishlistResponse[] }) =>
          wishlistAdapter.setAll(
            wishlistAdapter.getInitialState(),
            response?.data.map(wishlistSerializer),
          ),
        providesTags: (result: EntityState<Wishlist> | undefined) =>
          providesList(result?.ids, ApiTagTypes.WISHLIST),
      }),
    }),
  })

export const { useGetWishlistsQuery } = wishlistEndpoints
