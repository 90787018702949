import { sellerAdapter } from '@adapters/sellerAdapter'
import { sellerUsersAdapter } from '@adapters/sellerUsersAdapter'
import { DestructiveConfirm } from '@components/DestructiveConfirm/DestructiveConfirm'
import { useAppSelector } from '@hooks/redux'
import { User } from '@interfaces/entities/user/user'
import { ActionIcon, Badge, Stack, Table, Tabs, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useRemoveSellerUserMutation } from '@redux/seller/sellerEndpoints'
import { selectShops } from '@redux/seller/sellerSlice'
import { EntityState } from '@reduxjs/toolkit'
import { IconX } from '@tabler/icons-react'
import { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { InviteExistingForm } from './InviteExistingForm'
import { InviteForm } from './InviteForm'

export const Users = (): JSX.Element => {
  const { sellerId = '' } = useParams()
  const sellerEntityState = useAppSelector(selectShops)
  const [isRemoveSellerUserOpen, setIsRemoveSellerUserOpen] =
    useState<boolean>(false)
  const [activeSellerUserId, setActiveSellerUserId] = useState<
    string | undefined
  >()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [removeSellerUser, { isLoading }] = useRemoveSellerUserMutation()

  const seller =
    sellerEntityState &&
    sellerAdapter.getSelectors().selectById(sellerEntityState, sellerId)

  const users =
    sellerEntityState &&
    sellerUsersAdapter
      .getSelectors()
      .selectAll(seller?.users as EntityState<User>)

  const handleCloseRemoveSellerUser = () => {
    setIsRemoveSellerUserOpen(false)
    setActiveSellerUserId(undefined)
  }

  const handleOpenRemoveSellerUser = (sellerUserId: string) => {
    setIsRemoveSellerUserOpen(true)
    setActiveSellerUserId(sellerUserId)
  }

  const handleRemoveSellerUser = async () => {
    if (isSubmitting || !activeSellerUserId) return

    setIsSubmitting(true)

    try {
      await removeSellerUser(activeSellerUserId).unwrap()

      showNotification({
        message: `Seller user removed!`,
        color: 'green',
      })
    } catch (e) {
      showNotification({
        message: 'Unable to remove seller user!',
        color: 'red',
      })
    } finally {
      setIsSubmitting(false)
      handleCloseRemoveSellerUser()
    }
  }

  const getSellerUserName = () => {
    const user = sellerUsersAdapter
      .getSelectors()
      .selectById(
        seller?.users as EntityState<User>,
        activeSellerUserId as string,
      )

    return user?.name
  }

  return (
    <>
      <Tabs defaultValue="list">
        <Tabs.List h={50} grow>
          <Tabs.Tab value="list">
            <Text size="sm">Users</Text>
          </Tabs.Tab>
          <Tabs.Tab value="new">
            <Text size="sm">Add New User</Text>
          </Tabs.Tab>
          <Tabs.Tab value="existing">
            <Text size="sm">Add Existing User</Text>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="list">
          <Stack px="md" pb="md" data-autofocus>
            <Table verticalSpacing="md">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user: User) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.sellerRole}</td>
                    <td>
                      <Badge
                        size="sm"
                        variant="outline"
                        color={user.isActive ? 'green' : 'gray'}
                        radius="sm"
                      >
                        {user.isActive ? 'Active' : 'Inactive'}
                      </Badge>
                    </td>
                    <td>
                      <ActionIcon
                        color="red"
                        ml="sm"
                        onClick={() =>
                          handleOpenRemoveSellerUser(
                            user.sellerUserId as string,
                          )
                        }
                      >
                        <IconX size="1rem" />
                      </ActionIcon>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="new">
          <InviteForm />
        </Tabs.Panel>

        <Tabs.Panel value="existing">
          <InviteExistingForm />
        </Tabs.Panel>
      </Tabs>

      <DestructiveConfirm
        confirmText="Remove User"
        opened={isRemoveSellerUserOpen}
        onCancel={handleCloseRemoveSellerUser}
        onConfirm={handleRemoveSellerUser}
        isLoading={isLoading}
      >
        <Text>
          Are you sure you would like to remove{' '}
          <strong>{getSellerUserName()}</strong> from{' '}
          <strong>{seller?.name}</strong>?
        </Text>
      </DestructiveConfirm>

      <Outlet />
    </>
  )
}
