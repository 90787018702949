import { sellerAdapter } from '@adapters/sellerAdapter'
import { Seller } from '@interfaces/entities/seller/seller'
import type { RootState } from '@redux/store'
import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface SellerState {
  shops: EntityState<Seller> | undefined
  isLoading: boolean
  error: unknown
}

const initialState: SellerState = {
  shops: sellerAdapter.setAll(sellerAdapter.getInitialState(), []),
  isLoading: false,
  error: null,
}

export const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    setShops: (state, action: PayloadAction<SellerState>) => {
      const { shops, isLoading, error } = action.payload

      state.shops = shops
      state.isLoading = isLoading
      state.error = error
    },
  },
})

export const { setShops } = sellerSlice.actions

export const selectShops = (state: RootState) => state.seller.shops
export const selectShopsIsLoading = (state: RootState) => state.seller.isLoading
export const selectShopsError = (state: RootState) => state.seller.error
