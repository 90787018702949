import { FormError } from '@components/FormError/FormError'
import { Section } from '@components/Section/Section'
import { MutationError } from '@interfaces/mutationError'
import { Button, CardSection, PasswordInput, Stack } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useChangePasswordMutation } from '@redux/account/accountEndpoints'
import {
  changePasswordInitialState,
  changePasswordSchema,
  ChangePasswordState,
} from '@schemas/account/change-password'
import { useState } from 'react'

export const ChangePassword = (): JSX.Element => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()

  const [changePassword, { isSuccess }] = useChangePasswordMutation()

  const { onSubmit, isDirty, getInputProps, reset } = useForm({
    initialValues: changePasswordInitialState(),
    validate: yupResolver(changePasswordSchema),
  })

  const handleSubmit = async (values: ChangePasswordState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      const _values = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }

      await changePassword(_values).unwrap()

      showNotification({
        message: 'Password updated!',
        color: 'green',
      })

      reset()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Section title="Change Password">
      <CardSection p="md">
        <form
          onSubmit={onSubmit((values: ChangePasswordState) =>
            handleSubmit(values),
          )}
        >
          <Stack w={350}>
            {!isSuccess && formError && <FormError error={formError} />}

            <PasswordInput
              placeholder="Old Password"
              {...getInputProps('oldPassword')}
            />
            <PasswordInput
              placeholder="New Password"
              {...getInputProps('newPassword')}
            />
            <PasswordInput
              placeholder="Confirm New Password"
              {...getInputProps('confirmNewPassword')}
            />
            <Button type="submit" loading={isDirty() && isSubmitting}>
              Update Password
            </Button>
          </Stack>
        </form>
      </CardSection>
    </Section>
  )
}
