import { Card, CardSection, Group, Title } from '@mantine/core'
import { ReactNode } from 'react'

export interface SectionProps {
  title: string | ReactNode
  rightSection?: ReactNode | ReactNode[]
  children: ReactNode | ReactNode[]
}

export const Section = (props: SectionProps): JSX.Element => {
  const { title, children, rightSection } = props

  return (
    <Card withBorder>
      <CardSection p="md" withBorder>
        <Group position="apart">
          {typeof title === 'string' ? <Title order={5}>{title}</Title> : title}
          {rightSection}
        </Group>
      </CardSection>
      {children}
    </Card>
  )
}
