import { brandAdapter } from '@adapters/brandAdapter'
import { FormError } from '@components/FormError/FormError'
import { Modal } from '@components/Modal/Modal'
import { Brand } from '@interfaces/entities/brand/brand'
import { MutationError } from '@interfaces/mutationError'
import { Button, Group, Stack, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useEditBrandMutation } from '@redux/brand/brandEndpoints'
import { EntityState } from '@reduxjs/toolkit'
import {
  editBrandInitialState,
  editBrandSchema,
  EditBrandState,
} from '@schemas/brand/editBrand'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

export const EditBrand = (): JSX.Element => {
  const navigate = useNavigate()
  const { brandId = '' } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()
  const [editSeller, { isSuccess }] = useEditBrandMutation()
  const {
    brandEntityState,
  }: {
    brandEntityState: EntityState<Brand>
  } = useOutletContext()

  const brand =
    brandEntityState &&
    (brandAdapter.getSelectors().selectById(brandEntityState, brandId) as Brand)

  const { onSubmit, isDirty, getInputProps, setFieldValue } = useForm({
    initialValues: editBrandInitialState(brand as EditBrandState),
    validate: yupResolver(editBrandSchema),
  })

  const handleClose = () => navigate('..')

  const handleSubmit = async (values: EditBrandState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      await editSeller({ brandId, ...values }).unwrap()

      showNotification({
        message: `${brand?.name} edited!`,
        color: 'green',
      })

      handleClose()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    setFieldValue('name', brand?.name)
    setFieldValue('website', brand?.website)
    setFieldValue('description', brand?.description)
  }, [brand])

  return (
    <Modal title="Edit Brand" opened={true} onClose={handleClose}>
      <form
        onSubmit={onSubmit((values: EditBrandState) => handleSubmit(values))}
      >
        <Stack>
          {!isSuccess && formError && <FormError error={formError} />}

          <TextInput
            label="Name"
            placeholder="Name"
            data-autofocus
            {...getInputProps('name')}
          />
          <TextInput
            label="Website"
            placeholder="Website"
            {...getInputProps('website')}
          />
          <TextInput
            label="Description"
            placeholder="Description"
            {...getInputProps('description')}
          />

          <Group position="right">
            <Button variant="default" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" loading={isDirty() && isSubmitting}>
              Edit
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}
