import * as Yup from 'yup'

export const loginSchema = Yup.object({
  email: Yup.string().required('Email is required').email(),
  password: Yup.string().required('Password is required'),
})

export type LoginState = Yup.InferType<typeof loginSchema>

export const loginInitialState: () => LoginState = () => ({
  email: '',
  password: '',
})
