import { sellerAdapter } from '@adapters/sellerAdapter'
import { Content } from '@components/Content/Content'
import { EmptyState } from '@components/EmptyState/EmptyState'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { Seller } from '@interfaces/entities/seller/seller'
import { RoutePaths } from '@interfaces/routePaths'
import { Badge, Button, CardSection, Group, Text, Title } from '@mantine/core'
import {
  selectShops,
  selectShopsError,
  selectShopsIsLoading,
} from '@redux/seller/sellerSlice'
import { selectActiveSeller } from '@redux/site/siteSlice'
import { EntityState } from '@reduxjs/toolkit'
import { IconPlus } from '@tabler/icons-react'
import { Outlet, useNavigate } from 'react-router-dom'

export const ShopsList = (): JSX.Element => {
  const navigate = useNavigate()
  const activeSeller = useAppSelector(selectActiveSeller)
  const shops = useAppSelector(selectShops)
  const isLoading = useAppSelector(selectShopsIsLoading)
  const error = useAppSelector(selectShopsError)

  const sellers =
    !isLoading && !error
      ? sellerAdapter.getSelectors().selectAll(shops as EntityState<Seller>) ??
        []
      : []

  const handleNavigate = () => navigate(RoutePaths.SHOPS_CREATE)

  const handleClick = (sellerId: string) =>
    navigate(`../${RoutePaths.SHOPS_PROFILE}/${sellerId}`)

  return (
    <Content>
      {shops && (
        <>
          {sellers.length > 0 ? (
            <Section
              title={
                <Title order={5}>
                  Shops{' '}
                  <Text color="gray.6" fw="normal" span>
                    ({sellers.length})
                  </Text>
                </Title>
              }
              rightSection={
                <Button
                  leftIcon={<IconPlus size="0.8rem" />}
                  onClick={handleNavigate}
                >
                  New Seller Profile
                </Button>
              }
            >
              {sellers.map((seller: Seller, i: number) => (
                <CardSection
                  key={seller.id}
                  p="md"
                  onClick={() => handleClick(seller.id)}
                  withBorder={i !== 0}
                  sx={(theme) => ({
                    cursor: 'pointer',
                    '&:hover': {
                      '[data-seller]': {
                        color: theme.colors.violet,
                      },
                    },
                  })}
                >
                  <Group position="apart">
                    <Text fw="bold" size="sm" color="gray.6" data-seller>
                      {seller.name}
                    </Text>
                    <Group>
                      {activeSeller && activeSeller.id === seller.id && (
                        <Badge color="pink">Current</Badge>
                      )}
                      {seller.isActive ? (
                        <Badge color="green">Active</Badge>
                      ) : (
                        <Badge color="gray">Inactive</Badge>
                      )}
                    </Group>
                  </Group>
                </CardSection>
              ))}
            </Section>
          ) : (
            <>
              <EmptyState type="shops" createPath={RoutePaths.SHOPS_CREATE} />
            </>
          )}
        </>
      )}

      <Outlet />
    </Content>
  )
}
