import LogoSrc from '@assets/nabthis.webp'
import { MantineSize, useMantineTheme } from '@mantine/core'

export interface LogoProps {
  centered?: boolean
  mt?: MantineSize
  mb?: MantineSize
  ml?: MantineSize
  mr?: MantineSize
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
}
export const Logo = ({
  size = 'sm',
  centered,
  mt,
  mb,
  mr,
  ml,
}: LogoProps): JSX.Element => {
  const theme = useMantineTheme()

  const sizes = {
    xs: '12%',
    sm: '90px',
    md: '30%',
    lg: '40%',
    xl: '50%',
    full: '100%',
  }

  const styles = centered
    ? {
        margin: '0 auto',
      }
    : {}

  return (
    <img
      src={LogoSrc}
      width={sizes[size]}
      style={{
        display: 'block',
        marginTop: mt ? theme.spacing[mt] : 0,
        marginBottom: mb ? theme.spacing[mb] : 0,
        marginLeft: ml && !centered ? theme.spacing[ml] : 0,
        marginRight: mr && !centered ? theme.spacing[mr] : 0,
        ...styles,
      }}
    />
  )
}
