import { Roles } from '@interfaces/roles'
import type { RootState } from '@redux/store'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface AccountState {
  id: string
  email: string
  name: string
  role: Roles | string
  isActive: boolean
}

const initialState: AccountState = {
  id: '',
  email: '',
  name: '',
  role: '',
  isActive: true,
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountInformation: (state, action: PayloadAction<AccountState>) => {
      const { id, email, name, role, isActive } = action.payload

      state.id = id
      state.email = email
      state.name = name
      state.role = role
      state.isActive = isActive
    },
    clearAccountInformation: (state: AccountState) => {
      state = initialState

      return state
    },
  },
})

export const { setAccountInformation, clearAccountInformation } =
  accountSlice.actions

export const selectAccountInformation = (state: RootState) => state.account
