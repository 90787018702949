import { FormError } from '@components/FormError/FormError'
import { SplitPanel } from '@components/SplitPanel/SplitPanel'
import { MutationError } from '@interfaces/mutationError'
import { RoutePaths } from '@interfaces/routePaths'
import { Anchor, Button, Stack, Text, Title } from '@mantine/core'
import { useConfirmRegistrationMutation } from '@redux/auth/authEndpoints'
import { IconArrowNarrowRight } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export const VerifyEmail = () => {
  const navigate = useNavigate()
  const { code } = useParams()
  const [queryParams] = useSearchParams()
  const email = queryParams.get('email')

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [formError, setFormError] = useState<MutationError | null>()

  const [confirmRegistration, { isSuccess }] = useConfirmRegistrationMutation()

  const retryLink = `${RoutePaths.VERIFY_EMAIL.replace(
    ':code',
    code ?? '',
  )}?email=${encodeURI(email ?? '')}`

  useEffect(() => {
    async function verify() {
      if (formError || !code || !email || isSuccess || isSubmitting) return

      setIsSubmitting(true)
      setFormError(null)

      try {
        await confirmRegistration({
          code,
          email,
        }).unwrap()
      } catch (e) {
        setFormError(e)
      } finally {
        setIsSubmitting(false)
      }
    }
    verify()
  }, [])

  return (
    <SplitPanel title="Email Verification" hideTitles={true}>
      {isSubmitting ? (
        <Stack spacing="lg">
          <Text align="center">
            Verifying email address:{' '}
            <Text color="violet" span={true}>
              {email}
            </Text>
            ...
          </Text>
        </Stack>
      ) : (
        <Stack>
          {isSuccess && (
            <>
              <Stack>
                <Title order={4} align="center">
                  Email address verified!
                </Title>
                <Button
                  onClick={() => navigate(RoutePaths.LOGIN)}
                  leftIcon={<IconArrowNarrowRight />}
                  variant="white"
                  color="gray"
                >
                  Sign in
                </Button>
              </Stack>
            </>
          )}
          {!isSuccess && formError && (
            <>
              <FormError error={formError} />
              <Anchor href={retryLink} align="center" size="md" span={false}>
                Try again
              </Anchor>
            </>
          )}
        </Stack>
      )}
    </SplitPanel>
  )
}
