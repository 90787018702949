import { RoutePaths } from '@interfaces/routePaths'
import { Base } from '@views/Wishlists/Base'
import { RouteObject } from 'react-router-dom'

export const WISHLIST_PATHS: RouteObject[] = [
  {
    path: RoutePaths.WISHLISTS,
    element: <Base />,
  },
]
