import { FormError } from '@components/FormError/FormError'
import { SplitPanel } from '@components/SplitPanel/SplitPanel'
import { MutationError } from '@interfaces/mutationError'
import { RoutePaths } from '@interfaces/routePaths'
import { Button, PasswordInput, Stack, Title } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useResetPasswordMutation } from '@redux/auth/authEndpoints'
import {
  resetPasswordInitialState,
  resetPasswordSchema,
  ResetPasswordState,
} from '@schemas/auth/resetPassword'
import { IconArrowNarrowRight } from '@tabler/icons-react'
import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export const ResetPassword = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const email = queryParams.get('email')

  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()

  const [resetPassword, { isSuccess }] = useResetPasswordMutation()

  const { onSubmit, isDirty, getInputProps } = useForm({
    initialValues: resetPasswordInitialState(),
    validate: yupResolver(resetPasswordSchema),
  })

  const handleSubmit = async (values: ResetPasswordState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      const _values = {
        ...values,
        email,
        code,
      }

      await resetPassword(_values).unwrap()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <SplitPanel title="Reset Password" hideTitles={isSuccess}>
      {isSuccess ? (
        <Stack>
          <Title order={4} align="center">
            Your password has been reset
          </Title>
          <Button
            onClick={() => navigate(RoutePaths.LOGIN)}
            leftIcon={<IconArrowNarrowRight />}
            variant="white"
            color="gray"
          >
            Sign in
          </Button>
        </Stack>
      ) : (
        <form
          onSubmit={onSubmit((values: ResetPasswordState) =>
            handleSubmit(values),
          )}
        >
          <Stack>
            {!isSuccess && formError && <FormError error={formError} />}
            <PasswordInput
              placeholder="New Password"
              {...getInputProps('newPassword')}
            />
            <PasswordInput
              placeholder="Confirm New Password"
              {...getInputProps('confirmPassword')}
            />
            <Button type="submit" loading={isDirty() && isSubmitting}>
              Set New Password
            </Button>
          </Stack>
        </form>
      )}
    </SplitPanel>
  )
}
