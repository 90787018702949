import * as Yup from 'yup'

export const inviteAdminSchema = Yup.object({
  email: Yup.string().required('Email is required').email(),
  role: Yup.string().required('Role is required'),
})

export type InviteAdminState = Yup.InferType<typeof inviteAdminSchema>

export const inviteAdminInitialState: () => InviteAdminState = () => ({
  email: '',
  role: '',
})
