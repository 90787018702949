import { EndpointPaths } from '@interfaces/endpointPaths'
import { apiSlice } from '@redux/apiSlice'

export const authEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (body) => ({
        url: EndpointPaths.AUTH_SIGNUP,
        method: 'POST',
        body: { ...body },
      }),
    }),
    connect: builder.mutation({
      query: (body) => ({
        url: EndpointPaths.AUTH_CONNECT,
        method: 'POST',
        body: { ...body },
      }),
    }),
    invite: builder.mutation({
      query: (body) => ({
        url: EndpointPaths.AUTH_INVITE,
        method: 'POST',
        body: { ...body },
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: EndpointPaths.AUTH_LOGIN,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    confirmRegistration: builder.mutation({
      query: (body) => ({
        url: EndpointPaths.AUTH_VERIFY_EMAIL,
        method: 'POST',
        body: { ...body },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: EndpointPaths.AUTH_FORGOT_PASSWORD,
        method: 'POST',
        body: { ...body },
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: EndpointPaths.AUTH_RESET_PASSWORD,
        method: 'POST',
        body: { ...body },
      }),
    }),
    resendVerificationEmail: builder.mutation({
      query: (body) => ({
        url: EndpointPaths.AUTH_RESEND_VERIFICATION_EMAIL,
        method: 'POST',
        body: { ...body },
      }),
    }),
  }),
})

export const {
  useSignupMutation,
  useLoginMutation,
  useConnectMutation,
  useInviteMutation,
  useConfirmRegistrationMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useResendVerificationEmailMutation,
} = authEndpoints
