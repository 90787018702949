import { Block } from '@components/Block/Block'
import { MutationError } from '@interfaces/mutationError'
import { Text, useMantineTheme } from '@mantine/core'
import { isStrEmpty } from '@utils/isStrEmpty'

export type FormErrorProps = {
  width?: number | string
  error: MutationError
}

export const FormError = (props: FormErrorProps) => {
  const { error, width } = props
  const theme = useMantineTheme()

  const message =
    error?.data?.message && !isStrEmpty(String(error?.data?.message))
      ? error.data.message
      : 'Something went wrong...'

  return (
    <Block
      mb="md"
      p="xs"
      w={width}
      style={{
        backgroundColor: theme.colors.red[0],
        border: `solid 1px ${theme.colors.red[4]}`,
        borderRadius: theme.radius[theme.defaultRadius as string],
      }}
    >
      <Text size="sm">
        {Array.isArray(message) ? message.join(', ') : message}
      </Text>
    </Block>
  )
}
