import { FormError } from '@components/FormError/FormError'
import { MutationError } from '@interfaces/mutationError'
import { Roles } from '@interfaces/roles'
import { SellerRoles } from '@interfaces/seller-roles'
import { Box, Button, Select, Stack, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useInviteMutation } from '@redux/auth/authEndpoints'
import {
  inviteInitialState,
  inviteSchema,
  InviteState,
} from '@schemas/invite/invite'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export const InviteForm = (): JSX.Element => {
  const { sellerId } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()
  const [invite, { isSuccess }] = useInviteMutation()

  const { onSubmit, isDirty, getInputProps, reset } = useForm({
    initialValues: inviteInitialState(),
    validate: yupResolver(inviteSchema),
  })

  const sellerRoles = Object.values(SellerRoles).map((sellerRole: string) => ({
    label: sellerRole,
    value: sellerRole,
  }))

  const handleSubmit = async (values: InviteState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      await invite({
        email: values.email,
        sellerRole: values.role,
        role: Roles.CLIENT,
        sellerId,
      }).unwrap()

      showNotification({
        message: `Invite sent!`,
        color: 'green',
      })

      reset()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Box p="md">
      <form onSubmit={onSubmit((values: InviteState) => handleSubmit(values))}>
        <Stack>
          {!isSuccess && formError && <FormError error={formError} />}

          <TextInput
            type="email"
            placeholder="Email"
            {...getInputProps('email')}
          />

          <Select
            data={sellerRoles}
            placeholder="Role"
            dropdownPosition="bottom"
            withinPortal
            {...getInputProps('role')}
          />

          <Button type="submit" loading={isDirty() && isSubmitting}>
            Send Invite
          </Button>
        </Stack>
      </form>
    </Box>
  )
}
