import { brandAdapter } from '@adapters/brandAdapter'
import { EmptyState } from '@components/EmptyState/EmptyState'
import { Section } from '@components/Section/Section'
import { Brand } from '@interfaces/entities/brand/brand'
import { RoutePaths } from '@interfaces/routePaths'
import { Badge, Button, CardSection, Group, Text, Title } from '@mantine/core'
import { EntityState } from '@reduxjs/toolkit'
import { IconPlus } from '@tabler/icons-react'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'

export const List = (): JSX.Element => {
  const navigate = useNavigate()
  const {
    brandEntityState,
    isLoading,
    error,
  }: {
    brandEntityState: EntityState<Brand>
    isLoading: boolean
    error: unknown
  } = useOutletContext()

  const brands =
    !isLoading && !error
      ? brandAdapter
          .getSelectors()
          .selectAll(brandEntityState as EntityState<Brand>) ?? []
      : []

  const handleNavigate = () => navigate(RoutePaths.ADMIN_BRANDS_CREATE)

  const handleClick = (brandId: string) =>
    navigate(`../${RoutePaths.ADMIN_BRANDS_DETAILS}/${brandId}`)

  return (
    <>
      {brandEntityState && !isLoading && !error && (
        <>
          {brands.length > 0 ? (
            <Section
              title={
                <Title order={5}>
                  Brands{' '}
                  <Text color="gray.6" fw="normal" span>
                    ({brands.length})
                  </Text>
                </Title>
              }
              rightSection={
                <Button
                  leftIcon={<IconPlus size="0.8rem" />}
                  onClick={handleNavigate}
                >
                  New Brand
                </Button>
              }
            >
              {brands.map((brand: Brand, i: number) => (
                <CardSection
                  key={brand.id}
                  p="md"
                  onClick={() => handleClick(brand.id)}
                  withBorder={i !== 0}
                  sx={(theme) => ({
                    cursor: 'pointer',
                    '&:hover': {
                      '[data-brand]': {
                        color: theme.colors.violet,
                      },
                    },
                  })}
                >
                  <Group position="apart">
                    <Text fw="bold" size="sm" color="gray.6" data-brand>
                      {brand.name}
                    </Text>
                    <Group>
                      {brand.isActive ? (
                        <Badge color="green">Active</Badge>
                      ) : (
                        <Badge color="gray">Inactive</Badge>
                      )}
                    </Group>
                  </Group>
                </CardSection>
              ))}
            </Section>
          ) : (
            <>
              <EmptyState
                type="brands"
                createPath={RoutePaths.ADMIN_BRANDS_CREATE}
              />
            </>
          )}
        </>
      )}

      <Outlet context={{ brandEntityState, isLoading, error }} />
    </>
  )
}
