import { Content } from '@components/Content/Content'
import { useSetSection } from '@hooks/useSetSection'
import { useGetAllProductsQuery } from '@redux/product/productEndpoints'
import { Outlet } from 'react-router-dom'

export const Base = (): JSX.Element => {
  useSetSection('Platform Products')

  const {
    data: productEntityState,
    isLoading,
    error,
  } = useGetAllProductsQuery()

  return (
    <Content>
      <Outlet context={{ productEntityState, isLoading, error }} />
    </Content>
  )
}
