import { userAdapter } from '@adapters/userAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { User } from '@interfaces/entities/user/user'
import { apiSlice, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { EntityState } from '@reduxjs/toolkit'

export const userEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USERS],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUsers: builder.query<EntityState<User>, string | null>({
        query: (query: string) => ({
          url: `${EndpointPaths.SEARCH_USERS}?query=${query}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: User[] }) =>
          userAdapter.setAll(userAdapter.getInitialState(), response?.data),
      }),
      getAllUsers: builder.query<EntityState<User>, void>({
        query: () => ({
          url: `${EndpointPaths.LIST_USERS}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: User[] }) =>
          userAdapter.setAll(userAdapter.getInitialState(), response?.data),
        providesTags: (result: EntityState<User> | undefined) =>
          providesList(result?.ids, ApiTagTypes.USERS),
      }),
    }),
  })

export const { useGetUsersQuery, useGetAllUsersQuery } = userEndpoints
