import { useAppSelector } from '@hooks/redux'
import { RoutePaths } from '@interfaces/routePaths'
import {
  Avatar,
  Box,
  Group,
  Menu,
  rem,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core'
import { selectAccountInformation } from '@redux/account/accountSlice'
import {
  IconChevronLeft,
  IconChevronRight,
  IconLogout,
  IconUser,
} from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

export function User() {
  const user = useAppSelector(selectAccountInformation)
  const theme = useMantineTheme()

  const navigate = useNavigate()

  return (
    <Box
      sx={{
        borderTop: `${rem(1)} solid ${theme.colors.gray[2]}`,
      }}
    >
      <Menu
        shadow="none"
        width={200}
        position="right"
        offset={{
          mainAxis: 10,
          crossAxis: -40,
        }}
      >
        <Menu.Target>
          <UnstyledButton
            sx={{
              display: 'block',
              width: '100%',
              padding: theme.spacing.xs,
              borderRadius: theme.radius[theme.defaultRadius as string],
              color: theme.black,

              '&:hover': {
                backgroundColor: theme.colors.gray[0],
              },
            }}
          >
            <Group>
              <Avatar radius="xl" color="pink" />
              <Box sx={{ flex: 1 }}>
                <Text size="sm" weight={500}>
                  {user.name}
                </Text>
                <Text color="dimmed" size="xs" truncate w={166}>
                  {user.email}
                </Text>
              </Box>

              {theme.dir === 'ltr' ? (
                <IconChevronRight size={rem(18)} />
              ) : (
                <IconChevronLeft size={rem(18)} />
              )}
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IconUser size={14} />}
            onClick={() =>
              navigate(`${RoutePaths.ACCOUNT}/${RoutePaths.ACCOUNT_DETAILS}`)
            }
          >
            Account
          </Menu.Item>
          <Menu.Divider />
          <Menu.Label>Danger zone</Menu.Label>
          <Menu.Item
            color="red"
            icon={<IconLogout size={14} />}
            onClick={() => navigate(RoutePaths.LOGOUT)}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  )
}
