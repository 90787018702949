import { productAdapter } from '@adapters/productAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { Product } from '@interfaces/entities/product/product'
import { ProductResponse } from '@interfaces/entities/product/productResponse'
import { apiSlice, invalidatesList, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { EntityState } from '@reduxjs/toolkit'
import { productSerializer } from '@serializers/productSerializer'

export const productEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.PRODUCTS, ApiTagTypes.PRODUCTS_ALL],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProducts: builder.query<EntityState<Product>, unknown>({
        query: ({ sellerId, category }) => ({
          url: `${EndpointPaths.PRODUCT}/${sellerId}?category=${category}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: ProductResponse[] }) =>
          productAdapter.setAll(
            productAdapter.getInitialState(),
            response?.data.map(productSerializer),
          ),
        providesTags: (result: EntityState<Product> | undefined) =>
          providesList(result?.ids, ApiTagTypes.PRODUCTS),
      }),
      getAllProducts: builder.query<EntityState<Product>, void>({
        query: () => ({
          url: `${EndpointPaths.PRODUCT}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: ProductResponse[] }) =>
          productAdapter.setAll(
            productAdapter.getInitialState(),
            response?.data.map(productSerializer),
          ),
        providesTags: (result: EntityState<Product> | undefined) =>
          providesList(result?.ids, ApiTagTypes.PRODUCTS_ALL),
      }),
      createProduct: builder.mutation({
        query: (body) => {
          const { photos, captions, ...rest } = body
          const bodyFormData = new FormData()

          Object.keys(rest).forEach((key) => {
            bodyFormData.append(key, rest[key])
          })

          photos.forEach((photo: File) =>
            bodyFormData.append('photos[]', photo),
          )

          captions.forEach((caption: string) =>
            bodyFormData.append('captions[]', caption),
          )

          return {
            url: EndpointPaths.PRODUCT,
            method: 'POST',
            body: bodyFormData,
            formData: true,
          }
        },
        invalidatesTags: [invalidatesList(ApiTagTypes.PRODUCTS)],
      }),
      editProduct: builder.mutation({
        query: ({ productId, ...body }) => ({
          url: `${EndpointPaths.PRODUCT}/${productId}`,
          method: 'PATCH',
          body: { ...body },
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.PRODUCTS)],
      }),
      deleteProduct: builder.mutation({
        query: (productId: string) => ({
          url: `${EndpointPaths.PRODUCT}/${productId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.PRODUCTS)],
      }),
      setProductStatus: builder.mutation({
        query: ({ productId, isActive }) => ({
          url: isActive
            ? `${EndpointPaths.PRODUCT_DISABLE}/${productId}`
            : `${EndpointPaths.PRODUCT_ENABLE}/${productId}`,
          method: 'POST',
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.PRODUCTS)],
      }),
    }),
  })

export const {
  useGetProductsQuery,
  useGetAllProductsQuery,
  useCreateProductMutation,
  useEditProductMutation,
  useDeleteProductMutation,
  useSetProductStatusMutation,
} = productEndpoints
