import { FormError } from '@components/FormError/FormError'
import { SplitPanel } from '@components/SplitPanel/SplitPanel'
import { useSetSection } from '@hooks/useSetSection'
import { MutationError } from '@interfaces/mutationError'
import { Button, PasswordInput, Stack, Text, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useSignupMutation } from '@redux/auth/authEndpoints'
import {
  signupInitialState,
  signupSchema,
  SignupState,
} from '@schemas/auth/signup'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SignupSuccess } from './modals/SignupSuccess'

export const Signup = (): JSX.Element => {
  useSetSection('Sign up')

  const [queryParams] = useSearchParams()
  const [formError, setFormError] = useState<MutationError | null>()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const [signup] = useSignupMutation()

  const code = queryParams.get('code')
  const seller = queryParams.get('seller')
  const email = queryParams.get('email') ?? ''

  const isSellerSignup = !!seller

  const { onSubmit, isDirty, getInputProps } = useForm({
    initialValues: signupInitialState(),
    validate: yupResolver(signupSchema),
  })

  const handleSubmit = async (values: SignupState) => {
    if (isSubmitting) return

    setIsSuccess(false)
    setSubmitting(true)
    setFormError(null)

    try {
      const { name, password } = values

      const _values = {
        code,
        email,
        name,
        password,
      }

      await signup(_values).unwrap()
      setIsSuccess(true)
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <SplitPanel
        title="Sign up"
        subtitle={
          isSellerSignup && (
            <>
              You've been invite to join NabThis by{' '}
              <Text color="pink" span>
                {seller}
              </Text>
              !
            </>
          )
        }
      >
        <form
          onSubmit={onSubmit((values: SignupState) => handleSubmit(values))}
        >
          <Stack>
            {formError && <FormError error={formError} />}
            <TextInput value={email} disabled />
            <TextInput
              placeholder="Name"
              autoComplete="off"
              {...getInputProps('name')}
            />
            <PasswordInput
              placeholder="Password"
              {...getInputProps('password')}
            />
            <PasswordInput
              placeholder="Confirm Password"
              {...getInputProps('confirmPassword')}
            />
            <Button type="submit" loading={isDirty() && isSubmitting}>
              {isSellerSignup ? <>Sign up and join {seller}</> : <>Sign up</>}
            </Button>
          </Stack>
        </form>
        {isSuccess && <SignupSuccess email={email} />}
      </SplitPanel>
    </>
  )
}
