import { FormError } from '@components/FormError/FormError'
import { Modal } from '@components/Modal/Modal'
import { MutationError } from '@interfaces/mutationError'
import { Button, Group, Stack, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useCreateBrandMutation } from '@redux/brand/brandEndpoints'
import {
  createBrandInitialState,
  createBrandSchema,
  CreateBrandState,
} from '@schemas/brand/createBrand'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const CreateBrand = (): JSX.Element => {
  const navigate = useNavigate()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()

  const [createBrand, { isSuccess }] = useCreateBrandMutation()

  const { onSubmit, isDirty, getInputProps } = useForm({
    initialValues: createBrandInitialState(),
    validate: yupResolver(createBrandSchema),
  })

  const handleClose = () => navigate('..')

  const handleSubmit = async (values: CreateBrandState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      await createBrand(values).unwrap()

      showNotification({
        message: 'Brand Created!',
        color: 'green',
      })

      handleClose()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal title="New Seller Profile" opened={true} onClose={handleClose}>
      <form
        onSubmit={onSubmit((values: CreateBrandState) => handleSubmit(values))}
      >
        <Stack>
          {!isSuccess && formError && <FormError error={formError} />}

          <TextInput
            placeholder="Name"
            data-autofocus
            withAsterisk
            {...getInputProps('name')}
          />
          <TextInput
            placeholder="Website"
            withAsterisk
            {...getInputProps('website')}
          />
          <TextInput
            placeholder="Description"
            withAsterisk
            {...getInputProps('description')}
          />

          <Group position="right">
            <Button variant="default" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" loading={isDirty() && isSubmitting}>
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}
