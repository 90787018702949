import { brandAdapter } from '@adapters/brandAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { Brand } from '@interfaces/entities/brand/brand'
import { apiSlice, invalidatesList, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { EntityState } from '@reduxjs/toolkit'

export const brandEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.BRANDS],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBrands: builder.query<EntityState<Brand>, void>({
        query: () => ({
          url: EndpointPaths.BRAND,
          method: 'GET',
        }),
        transformResponse: (response: { data: Brand[] }) =>
          brandAdapter.setAll(brandAdapter.getInitialState(), response?.data),
        providesTags: (result: EntityState<Brand> | undefined) =>
          providesList(result?.ids, ApiTagTypes.BRANDS),
      }),
      createBrand: builder.mutation({
        query: (body) => ({
          url: EndpointPaths.BRAND,
          method: 'POST',
          body: { ...body },
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.BRANDS)],
      }),
      editBrand: builder.mutation({
        query: ({ brandId, ...body }) => ({
          url: `${EndpointPaths.BRAND}/${brandId}`,
          method: 'PATCH',
          body: { ...body },
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.BRANDS)],
      }),
      deleteBrand: builder.mutation({
        query: (brandId) => ({
          url: `${EndpointPaths.BRAND}/${brandId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.BRANDS)],
      }),
      setBrandStatus: builder.mutation({
        query: ({ brandId, isActive }) => ({
          url: isActive
            ? `${EndpointPaths.BRANDS_DISABLE}/${brandId}`
            : `${EndpointPaths.BRANDS_ENABLE}/${brandId}`,
          method: 'POST',
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.BRANDS)],
      }),
    }),
  })

export const {
  useGetBrandsQuery,
  useCreateBrandMutation,
  useEditBrandMutation,
  useDeleteBrandMutation,
  useSetBrandStatusMutation,
} = brandEndpoints
