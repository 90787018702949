import { WishlistPreferences } from '@interfaces/entities/wishlist/wishlistPreferences'
import { WishlistPreferencesResponse } from '@interfaces/entities/wishlist/wishlistPreferencesResponse'
import { WishlistPreferencesTypes } from '@interfaces/entities/wishlist/wishlistPreferencesTypes'

export function wishlistPreferencesSerializer(
  prefs: WishlistPreferencesResponse,
): Partial<WishlistPreferences> {
  const { type, brandId, brand, condition, color, itemType, size, department } =
    prefs

  let struct

  switch (type) {
    case WishlistPreferencesTypes.BRAND:
      struct = {
        [WishlistPreferencesTypes.BRAND.toLocaleLowerCase()]: brandId
          ? brand.name
          : brand,
      }
      break
    case WishlistPreferencesTypes.COLOR:
      struct = {
        [WishlistPreferencesTypes.COLOR.toLocaleLowerCase()]: color,
      }
      break
    case WishlistPreferencesTypes.CONDITION:
      struct = {
        [WishlistPreferencesTypes.CONDITION.toLocaleLowerCase()]: condition,
      }
      break
    case WishlistPreferencesTypes.ITEM_TYPE:
      struct = {
        type: itemType,
      }
      break
    case WishlistPreferencesTypes.DEPARTMENT:
      struct = {
        [WishlistPreferencesTypes.DEPARTMENT.toLocaleLowerCase()]: department,
      }
      break
    case WishlistPreferencesTypes.SIZE:
      struct = {
        [WishlistPreferencesTypes.SIZE.toLocaleLowerCase()]: size,
      }
      break
  }

  return struct as Partial<WishlistPreferences>
}
