import { userAdapter } from '@adapters/userAdapter'
import { FormError } from '@components/FormError/FormError'
import { User } from '@interfaces/entities/user/user'
import { MutationError } from '@interfaces/mutationError'
import { Roles } from '@interfaces/roles'
import { SellerRoles } from '@interfaces/seller-roles'
import {
  Autocomplete,
  AutocompleteItem,
  Avatar,
  Box,
  Button,
  Group,
  Select,
  SelectItem,
  SelectItemProps,
  Stack,
  Text,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useDebouncedValue } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { useInviteMutation } from '@redux/auth/authEndpoints'
import { useGetUsersQuery } from '@redux/user/userEndpoints'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  inviteExistingInitialState,
  inviteExistingSchema,
  InviteExistingState,
} from '@schemas/invite/inviteExisting'
import { IconSearch } from '@tabler/icons-react'
import { forwardRef, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface ItemProps extends SelectItemProps {
  userId: string
  email: string
}

// eslint-disable-next-line react/display-name
const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ value, userId, email, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar color="pink" />

        <div>
          <Text>{value}</Text>
          <Text size="sm" color="dimmed">
            {email}
          </Text>
        </div>
      </Group>
    </div>
  ),
)

export const InviteExistingForm = (): JSX.Element => {
  const { sellerId } = useParams()
  const [users, setUsers] = useState<SelectItem[]>()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()
  const [invite, { isSuccess }] = useInviteMutation()

  const { onSubmit, isDirty, getInputProps, setFieldValue, values, reset } =
    useForm({
      initialValues: inviteExistingInitialState(),
      validate: yupResolver(inviteExistingSchema),
    })

  const [searchTerm] = useDebouncedValue(values.searchTerm, 200)

  const { data } = useGetUsersQuery(searchTerm || skipToken)

  const sellerRoles = Object.values(SellerRoles).map((sellerRole: string) => ({
    label: sellerRole,
    value: sellerRole,
  }))

  const handleItemSubmit = (user: AutocompleteItem) => {
    setFieldValue('userId', user.userId)
    setFieldValue('email', user.email)
  }

  const handleSubmit = async (values: InviteExistingState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      await invite({
        email: values.email,
        sellerRole: values.role,
        userId: values.userId,
        role: Roles.CLIENT,
        sellerId,
      }).unwrap()

      showNotification({
        message: `Invite sent!`,
        color: 'green',
      })

      reset()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const users = data
      ? userAdapter
          .getSelectors()
          .selectAll(data)
          .map((user: User) => ({
            key: user.id,
            value: user.name,
            userId: user.id,
            email: user.email,
          }))
      : []

    setUsers(users)
  }, [data])

  return (
    <Box p="md">
      <form
        onSubmit={onSubmit((values: InviteExistingState) =>
          handleSubmit(values),
        )}
      >
        <Stack>
          {!isSuccess && formError && <FormError error={formError} />}
          <Autocomplete
            placeholder="Find user by name or email"
            itemComponent={AutoCompleteItem}
            icon={<IconSearch size="0.9rem" />}
            onItemSubmit={handleItemSubmit}
            data={users ?? []}
            dropdownPosition="bottom"
            withinPortal
            filter={() => true}
            {...getInputProps('searchTerm')}
          />

          <Select
            data={sellerRoles}
            placeholder="Role"
            dropdownPosition="bottom"
            withinPortal
            {...getInputProps('role')}
          />

          <Button type="submit" loading={isDirty() && isSubmitting}>
            Send Invite
          </Button>
        </Stack>
      </form>
    </Box>
  )
}
