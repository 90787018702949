import { Content } from '@components/Content/Content'
import { TabNavigation } from '@components/TabNavigation/TabNavigation'
import { useSetSection } from '@hooks/useSetSection'
import { Outlet } from 'react-router-dom'
import { ACCOUNT_TABS } from './constants'

export const Base = (): JSX.Element => {
  useSetSection('Account')

  return (
    <>
      <TabNavigation tabs={ACCOUNT_TABS} />
      <Content>
        <Outlet />
      </Content>
    </>
  )
}
