import { sellerAdapter } from '@adapters/sellerAdapter'
import { Logo } from '@components/Logo/Logo'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { Seller } from '@interfaces/entities/seller/seller'
import {
  Anchor,
  AppShell,
  Box,
  Group,
  Header,
  Navbar,
  ScrollArea,
  Select,
  Title,
} from '@mantine/core'
import { selectAccountInformation } from '@redux/account/accountSlice'
import { useGetUserSellersQuery } from '@redux/seller/sellerEndpoints'
import {
  selectActiveSection,
  selectActiveSeller,
  setActiveSeller,
} from '@redux/site/siteSlice'
import { EntityState } from '@reduxjs/toolkit'
import { IconBuildingStore } from '@tabler/icons-react'
import { getDefaultLink } from '@utils/getDefaultLink'
import { ReactNode, useEffect, useState } from 'react'
import { NavLinks } from './NavLinks'
import { User } from './User'

export interface ShellProps {
  children: ReactNode | ReactNode[]
}

export const Shell = ({ children }: ShellProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectAccountInformation)
  const activeSeller = useAppSelector(selectActiveSeller)
  const activeSection = useAppSelector(selectActiveSection)
  const { data: sellersEntity } = useGetUserSellersQuery()

  const [sellerSelectValue, setSellerSelectValue] = useState<string>(
    activeSeller?.id,
  )

  const sellers = sellersEntity
    ? sellerAdapter.getSelectors().selectAll(sellersEntity)
    : []

  const logoLink = getDefaultLink(user.role)

  const SELECT_VALUES = sellers.map((seller: Seller) => ({
    label: seller.name,
    value: seller.id ?? '',
  }))

  useEffect(() => {
    if (activeSeller?.id) {
      setSellerSelectValue(activeSeller.id)
    }
  }, [activeSeller])

  const handleChange = (val: string) => {
    const seller =
      sellerAdapter
        .getSelectors()
        .selectById(sellersEntity as EntityState<Seller>, val) ?? ({} as Seller)

    dispatch(setActiveSeller({ seller: seller as Seller }))
  }

  return (
    <AppShell
      layout="alt"
      padding={0}
      fixed
      navbar={
        <Navbar width={{ base: 275 }} height="100%">
          <Navbar.Section pt="lg" pl="lg" pb="xl">
            <Anchor href={logoLink}>
              <Logo size="md" />
            </Anchor>
          </Navbar.Section>

          <Navbar.Section grow component={ScrollArea}>
            <NavLinks />
          </Navbar.Section>

          <Navbar.Section>
            <User />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <>
          <Header height={60} px="md">
            <Group h="100%" align="center" position="apart">
              <Title order={4}>{activeSection}</Title>
              {sellers.length > 0 && activeSeller && activeSeller?.id && (
                <Group>
                  <Select
                    placeholder="Current Seller"
                    data={SELECT_VALUES}
                    onChange={handleChange}
                    value={sellerSelectValue}
                    icon={<IconBuildingStore size="1rem" />}
                  />
                </Group>
              )}
            </Group>
          </Header>
        </>
      }
    >
      <Box
        sx={(theme) => ({
          minHeight: '100%',
          backgroundColor: theme.colors.gray[0],
        })}
      >
        {children}
      </Box>
    </AppShell>
  )
}
