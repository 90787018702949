import { ProductAsset } from '@interfaces/entities/productAsset/productAsset'
import { ProductAssetResponse } from '@interfaces/entities/productAsset/productAssetResponse'
import { buildUrl } from 'cloudinary-build-url'

export function productAssetSerializer(
  productAsset: ProductAssetResponse,
): ProductAsset {
  const { id, position, publicId, folder, cloudName, caption, metadata } =
    productAsset

  const _caption = caption && caption.length > 0 ? { caption } : {}
  const _width = metadata && metadata.width ? { width: metadata.width } : {}
  const _height = metadata && metadata.height ? { height: metadata.height } : {}

  return {
    id,
    position,
    thumbnail: buildUrl(`${folder}/${publicId}`, {
      cloud: {
        cloudName,
        secure: false,
        resourceType: 'image',
        storageType: 'upload',
      },
      transformations: {
        resize: {
          type: 'scale',
          width: 150,
          height: 150,
        },
      },
    }),
    url: buildUrl(`${folder}/${publicId}`, {
      cloud: {
        cloudName,
        secure: false,
        resourceType: 'image',
        storageType: 'upload',
      },
      transformations: {},
    }),
    ..._caption,
    ..._width,
    ..._height,
  } as ProductAsset
}
