import { brandAdapter } from '@adapters/brandAdapter'
import { productAdapter } from '@adapters/productAdapter'
import { productAssetAdapter } from '@adapters/productAssetAdapter'
import { FormError } from '@components/FormError/FormError'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { Brand } from '@interfaces/entities/brand/brand'
import { ProductAttributeTypes } from '@interfaces/entities/product/productAttributeTypes'
import { ProductCategory } from '@interfaces/entities/product/productCategory'
import { ProductColors } from '@interfaces/entities/product/productColors'
import { ProductCondition } from '@interfaces/entities/product/productCondition'
import { ProductDepartment } from '@interfaces/entities/product/productDepartment'
import { ProductSize } from '@interfaces/entities/product/productSize'
import { ProductType } from '@interfaces/entities/product/productType'
import { ProductAsset } from '@interfaces/entities/productAsset/productAsset'
import { MutationError } from '@interfaces/mutationError'
import {
  ActionIcon,
  Box,
  Button,
  CardSection,
  FileButton,
  Group,
  Image,
  Modal,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { useGetBrandsQuery } from '@redux/brand/brandEndpoints'
import {
  useEditProductMutation,
  useGetProductsQuery,
} from '@redux/product/productEndpoints'
import { selectActiveSeller } from '@redux/site/siteSlice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  editThriftProductInitialState,
  editThriftProductSchema,
  EditThriftProductState,
} from '@schemas/product/editThriftProduct'
import {
  IconArrowLeft,
  IconArrowRight,
  IconArrowsMaximize,
  IconBarcode,
  IconCategory,
  IconCloudUpload,
  IconColorFilter,
  IconCurrencyDollar,
  IconHanger,
  IconPhoto,
  IconPhotoMinus,
  IconPlus,
  IconRulerMeasure,
  IconSparkles,
  IconTrademark,
} from '@tabler/icons-react'
import { removeEmptyStringFields } from '@utils/removeEmptyStringFields'
import { arrayMoveImmutable } from 'array-move'
import currency from 'currency.js'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const EditThriftProduct = (): JSX.Element => {
  const navigate = useNavigate()
  const { productId = '' } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()
  const [editProduct, { isSuccess }] = useEditProductMutation()
  const seller = useAppSelector(selectActiveSeller)
  const category = ProductCategory.THRIFT
  const { data: brandEntityState } = useGetBrandsQuery()

  const { data: productEntityState } = useGetProductsQuery(
    seller && seller.id
      ? {
          sellerId: seller.id,
          category,
        }
      : skipToken,
  )

  const product =
    productEntityState &&
    productAdapter.getSelectors().selectById(productEntityState, productId)

  const productAssets =
    productEntityState &&
    product?.productAssets &&
    productAssetAdapter
      .getSelectors()
      .selectAll(product.productAssets)
      .map((photo: ProductAsset) => {
        return {
          ...photo,
          photo: photo.url,
        }
      })

  const {
    onSubmit,
    isDirty,
    getInputProps,
    values,
    insertListItem,
    removeListItem,
    setFieldValue,
  } = useForm({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValues: editThriftProductInitialState(product as any),
    validate: yupResolver(editThriftProductSchema),
  })

  const handleClose = () => navigate(`../details/${productId}`)

  const handleSubmit = async (values: EditThriftProductState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      const strippedValues = removeEmptyStringFields(values)
      const { photos: photoList, ...rest } = values

      const originalPhotos = photoList?.reduce((acc, photo, index) => {
        if (photo.id) {
          acc.push({
            id: photo.id,
            position: index,
          })
        }

        return acc
      }, [] as unknown[])

      const originalCaptions = photoList?.reduce((acc, photo) => {
        if (photo.id) {
          acc.push(photo.caption ?? '')
        }

        return acc
      }, [] as string[])

      const uploadedPhotos = photoList?.reduce((acc, photo) => {
        if (!photo.id && photo.photo) {
          acc.push(photo.photo)
        }

        return acc
      }, [] as unknown[])

      const uploadedPositions = photoList?.reduce((acc, photo, index) => {
        if (!photo.id && photo.photo) {
          acc.push({
            position: index,
          })
        }

        return acc
      }, [] as unknown[])

      const uploadedCaptions = photoList?.reduce((acc, photo) => {
        if (!photo.id && photo.photo) {
          acc.push(photo.caption ?? '')
        }

        return acc
      }, [] as string[])

      const price = parseInt(
        String(Math.ceil(currency(rest.price).value * 100)),
      )

      const _values = {
        productId,
        ...strippedValues,
        category,
        sellerId: seller.id,
        originalPhotos,
        originalCaptions,
        uploadedPhotos,
        uploadedCaptions,
        uploadedPositions,
        price,
      }

      await editProduct(_values).unwrap()

      showNotification({
        message: `${product?.title} edited!`,
        color: 'green',
      })

      handleClose()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    setFieldValue('title', product?.title ?? '')
    setFieldValue('brandId', product?.productBrand?.id ?? '')
    setFieldValue('sku', product?.sku ?? '')
    setFieldValue('type', product?.type ?? '')
    setFieldValue('description', product?.description ?? '')
    setFieldValue('price', product?.price ?? '')
    setFieldValue('size', product?.size ?? '')
    setFieldValue('condition', product?.condition ?? '')
    setFieldValue('department', product?.department ?? '')
    setFieldValue('color', product?.[ProductAttributeTypes.COLOR] ?? '')
    setFieldValue(
      'photos',
      productAssets ?? [
        {
          id: null,
          photo: null,
          thumbnail: null,
          caption: '',
        },
      ],
    )

    setFieldValue(
      'measurements',
      product?.[ProductAttributeTypes.MEASUREMENTS] ?? '',
    )
    setFieldValue(
      'stainsAndImperfections',
      product?.[ProductAttributeTypes.STAINS_AND_IMPERFECTIONS] ?? '',
    )
  }, [product])

  const brands = brandEntityState
    ? brandAdapter.getSelectors().selectAll(brandEntityState)
    : []

  const BRAND_VALUES = brands.map((brand: Brand) => ({
    label: brand.name,
    value: brand.id,
  }))

  const COLOR_VALUES = Object.values(ProductColors).map((color: string) => ({
    label: color,
    value: color,
  }))

  const SIZE_VALUES = Object.values(ProductSize).map((size: string) => ({
    label: size,
    value: size,
  }))

  const CONDITION_VALUES = Object.values(ProductCondition).map(
    (condition: string) => ({
      label: condition,
      value: condition,
    }),
  )

  const DEPARTMENT_VALUES = Object.values(ProductDepartment).map(
    (department: string) => ({
      label: department,
      value: department,
    }),
  )

  const TYPE_VALUES = Object.values(ProductType).map((type: string) => ({
    label: type,
    value: type,
  }))

  const addPhotoItem = () => {
    insertListItem('photos', {
      photo: null,
      caption: '',
    })
  }

  const removePhotoItem = (index: number) => {
    if (values.photos && values.photos.length > 1)
      removeListItem('photos', index)
  }

  const movePhotoItem = (oldIndex: number, newIndex: number) => {
    setFieldValue(
      'photos',
      arrayMoveImmutable(values?.photos ?? [], oldIndex, newIndex),
    )
  }

  const [fullImageView, { open, close }] = useDisclosure(false)
  const [currentMediaIndex, setCurrentMediaIndex] = useState<number | null>(
    null,
  )

  const handleOpenModal = (index: number) => {
    setCurrentMediaIndex(index)
    open()
  }

  const selectedImage =
    currentMediaIndex !== null
      ? values.photos?.[currentMediaIndex]?.photo
      : null

  const selectedImageUrl = selectedImage
    ? typeof selectedImage === 'string'
      ? selectedImage
      : URL.createObjectURL(selectedImage as Blob)
    : ''

  const photoFields =
    values.photos &&
    values.photos.map((_, index) => {
      const photo = values?.photos?.[index].photo
      const photoUrl = photo
        ? typeof photo === 'string'
          ? photo
          : URL.createObjectURL(photo as Blob)
        : ''

      return (
        <Group key={`photos-${index}`} spacing="xs" align="flex-start">
          <Stack spacing="xs">
            {values?.photos?.[index].photo && (
              <Box
                w={200}
                h={200}
                sx={(theme) => ({
                  position: 'relative',
                  overflow: 'hidden',
                  border: `solid 1px ${theme.colors.gray[4]}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover .action-icon': {
                    display: 'flex',
                  },
                })}
              >
                <Image
                  src={photoUrl}
                  onLoad={() => {
                    if (typeof photo !== 'string') {
                      URL.revokeObjectURL(photoUrl)
                    }
                  }}
                />
                <Tooltip
                  label="View Full Image"
                  color="dark"
                  withArrow
                  arrowSize={6}
                  sx={{
                    fontSize: '0.75rem',
                    padding: '4px 8px',
                  }}
                >
                  <ActionIcon
                    className="action-icon"
                    color="dark"
                    variant="light"
                    size="md"
                    sx={{
                      display: 'none',
                      position: 'absolute',
                      bottom: '8px',
                      right: '8px',
                    }}
                    onClick={() => handleOpenModal(index)}
                  >
                    <IconArrowsMaximize size="1rem" />
                  </ActionIcon>
                </Tooltip>
              </Box>
            )}
            {!values?.photos?.[index].photo && (
              <Box
                p="md"
                w={200}
                h={200}
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `solid 1px ${theme.colors.gray[4]}`,
                  backgroundColor: theme.colors.gray[1],
                })}
              >
                <IconPhoto size="2rem" />
              </Box>
            )}
            <Group grow spacing="xs">
              {values?.photos?.[index].photo && index === 0 ? (
                <ActionIcon variant="outline" color="teal">
                  <Text size="xs" fw={700}>
                    Primary
                  </Text>
                </ActionIcon>
              ) : (
                <ActionIcon
                  variant="outline"
                  color="gray"
                  disabled={
                    !values?.photos?.[index].photo ||
                    index === 0 ||
                    !values?.photos?.[index - 1].photo
                  }
                  onClick={() => movePhotoItem(index, index - 1)}
                >
                  <IconArrowLeft size="1rem" />
                </ActionIcon>
              )}
              <ActionIcon
                variant="outline"
                disabled={
                  !values?.photos?.[index].photo ||
                  index + 1 === values?.photos?.length ||
                  !values?.photos?.[index + 1].photo
                }
                onClick={() => movePhotoItem(index, index + 1)}
              >
                <IconArrowRight size="1rem" />
              </ActionIcon>
            </Group>
            {!values?.photos?.[index].photo && (
              <FileButton
                accept="image/png,image/jpeg,image/jpg"
                {...getInputProps(`photos.${index}.photo`)}
              >
                {(props) => (
                  <Button
                    variant="outline"
                    leftIcon={<IconCloudUpload size="0.9rem" />}
                    disabled={values?.photos?.[index].photo ? true : false}
                    {...props}
                  >
                    Select Image
                  </Button>
                )}
              </FileButton>
            )}
            {(values?.photos?.[index]?.photo || index !== 0) && (
              <>
                <TextInput
                  placeholder="Add Caption"
                  style={{ flexGrow: 1 }}
                  {...getInputProps(`photos.${index}.caption`)}
                />
                <Button
                  variant="outline"
                  size="xs"
                  color="red"
                  leftIcon={<IconPhotoMinus size="0.9rem" />}
                  onClick={() => removePhotoItem(index)}
                >
                  Remove
                </Button>
              </>
            )}
          </Stack>
        </Group>
      )
    })

  return (
    <>
      <form
        onSubmit={onSubmit((values: EditThriftProductState) =>
          handleSubmit(values),
        )}
      >
        <Section title="Edit Thrift Product">
          <CardSection p="md">
            {!isSuccess && formError && <FormError error={formError} />}

            <TextInput
              label="Title"
              description="Enter a title for the product"
              placeholder="Title"
              data-autofocus
              withAsterisk
              {...getInputProps('title')}
            />
          </CardSection>
        </Section>

        <Stack pt="md">
          <Section title="Product Media">
            <CardSection p="md">
              <SimpleGrid cols={3} spacing="xl">
                {photoFields}
                <Group spacing="xs" align="flex-start">
                  <Stack>
                    <Box
                      p="md"
                      w={200}
                      h={200}
                      sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `solid 1px ${theme.colors.gray[4]}`,
                        backgroundColor: theme.colors.gray[0],
                        cursor: 'pointer',
                      })}
                      onClick={addPhotoItem}
                    >
                      <IconCloudUpload size="2rem" />
                    </Box>
                    <Button
                      variant="outline"
                      leftIcon={<IconPlus size="0.9rem" />}
                      onClick={addPhotoItem}
                    >
                      Add Another Image
                    </Button>
                  </Stack>
                </Group>
              </SimpleGrid>
            </CardSection>
          </Section>
          {selectedImageUrl && (
            <Modal
              opened={fullImageView}
              onClose={close}
              size="auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                img: {
                  maxWidth: '100%',
                  maxHeight: 'calc(100vh - 40px)',
                },
              }}
            >
              <Image
                src={selectedImageUrl}
                onLoad={() => {
                  if (typeof selectedImage !== 'string') {
                    URL.revokeObjectURL(selectedImageUrl)
                  }
                }}
              />
            </Modal>
          )}
        </Stack>

        <Stack pt="md">
          <Section title="Product Details">
            <CardSection p="md">
              <Stack>
                <TextInput
                  label="SKU"
                  description="Enter the SKU of your product"
                  icon={<IconBarcode size="1rem" />}
                  placeholder="SKU"
                  withAsterisk
                  {...getInputProps('sku')}
                />
                <Textarea
                  label="Description"
                  description="Which department does this product belong to?"
                  placeholder="Description"
                  autosize
                  minRows={3}
                  withAsterisk
                  {...getInputProps('description')}
                />

                <TextInput
                  label="Price"
                  description="Enter Price in USD"
                  icon={<IconCurrencyDollar size="1rem" />}
                  placeholder="Price"
                  withAsterisk
                  {...getInputProps('price')}
                />
                <Select
                  label="Type"
                  description="Select the type of the product. Such as: Sweatshirts, Pants, Jackets, etc."
                  icon={<IconHanger size="1rem" />}
                  placeholder="Select Type"
                  data={TYPE_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('type')}
                />
                <Select
                  label="Brand"
                  description="Select a brand from the list of approved brands"
                  icon={<IconTrademark size="1.1rem" />}
                  placeholder="Select Brand"
                  data={BRAND_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('brandId')}
                />
                <Select
                  label="Department"
                  description="Which department does this product belong to?"
                  icon={<IconCategory size="1rem" />}
                  placeholder="Select Department"
                  data={DEPARTMENT_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('department')}
                />
                <Select
                  label="Size"
                  description="Please refer to our size chart and select the appropriate sizing"
                  icon={<IconRulerMeasure size="1rem" />}
                  placeholder="Select Size"
                  data={SIZE_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('size')}
                />
                <Select
                  label="Color"
                  description="Select a color that best describes the product"
                  icon={<IconColorFilter size="1rem" />}
                  placeholder="Select Color"
                  data={COLOR_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('color')}
                />
                <Select
                  label="Condition"
                  description="Please follow our guidelines carefully when selecting the condition"
                  icon={<IconSparkles size="1rem" />}
                  placeholder="Select Condition"
                  data={CONDITION_VALUES}
                  searchable
                  nothingFound="No matches found"
                  clearable
                  withAsterisk
                  {...getInputProps('condition')}
                />
                <Textarea
                  label="Stains and Imperfections"
                  description="Enter information on stains and imperfections"
                  placeholder="Stains and Imperfections"
                  autosize
                  minRows={3}
                  {...getInputProps('stainsAndImperfections')}
                />
                <Textarea
                  label="Measurements"
                  description="Enter custom measurements, if any"
                  placeholder="Measurements (IE: Chest - 15)"
                  autosize
                  minRows={3}
                  {...getInputProps('measurements')}
                />
              </Stack>
            </CardSection>
          </Section>

          <CardSection p="md">
            <Group position="right">
              <Button variant="default" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" loading={isDirty() && isSubmitting}>
                Update
              </Button>
            </Group>
          </CardSection>
        </Stack>
      </form>
    </>
  )
}
