import { isStrEmpty } from './isStrEmpty'

export function removeEmptyStringFields(obj: object) {
  return Object.entries(obj).reduce(
    (acc: Record<string, string>, [key, val]) => {
      if (isStrEmpty(String(val ?? ''))) return acc
      acc[key] = val
      return acc
    },
    {},
  )
}
