import { CACHE_PREFETCH } from '@constants/cache'
import { sellerEndpoints } from '@redux/seller/sellerEndpoints'
import { store } from '@redux/store'

export function allSellersPrefetch() {
  store.dispatch(
    sellerEndpoints.util.prefetch('getAllSellers', undefined, {
      ifOlderThan: CACHE_PREFETCH,
    }),
  )
}
