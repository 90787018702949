import { Attribute } from '@interfaces/attribute/attribute'
import { ProductAttributeTypes } from '@interfaces/entities/product/productAttributeTypes'
import * as Yup from 'yup'

export const editThriftProductSchema = Yup.object({
  title: Yup.string().required('Name is required'),
  brandId: Yup.string().required('Brand is required'),
  sku: Yup.string().required('SKU is required'),
  type: Yup.string().required('Type is required'),
  description: Yup.string().required('Description is required'),
  color: Yup.string().required('Color is required'),
  price: Yup.string().required('Price is required'),
  size: Yup.string().required('Size is required'),
  condition: Yup.string().required('Condition is required'),
  department: Yup.string().required('Department is required'),
  stainsAndImperfections: Yup.string().optional(),
  measurements: Yup.string().optional(),
  photos: Yup.array().of(
    Yup.object({
      id: Yup.string().optional().nullable(),
      thumbnail: Yup.string().optional().nullable(),
      photo: Yup.mixed().optional().nullable(),
      caption: Yup.string().optional().nullable(),
    }),
  ),
})

export type EditThriftProductState = Yup.InferType<
  typeof editThriftProductSchema
> &
  Attribute

export const editThriftProductInitialState: (
  defaults: Partial<EditThriftProductState>,
) => EditThriftProductState = (defaults: Partial<EditThriftProductState>) => {
  return {
    title: defaults?.title ?? '',
    brandId: defaults?.productBrand?.id ?? '',
    sku: defaults?.sku ?? '',
    type: defaults?.type ?? '',
    description: defaults?.description ?? '',
    color: defaults?.[ProductAttributeTypes.COLOR] ?? '',
    price: defaults?.price ?? '',
    size: defaults?.size ?? '',
    condition: defaults?.condition ?? '',
    department: defaults?.department ?? '',
    stainsAndImperfections:
      defaults?.[ProductAttributeTypes.STAINS_AND_IMPERFECTIONS] ?? '',
    measurements: defaults?.[ProductAttributeTypes.MEASUREMENTS] ?? '',
    photos: defaults.photos ?? [
      {
        id: null,
        photo: null,
        thumbnail: null,
        caption: '',
      },
    ],
  }
}
