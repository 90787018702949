import { persistor, store } from '@redux/store'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { AppRoutes } from './routes/AppRoutes'

import './main.scss'

import { MantineProvider } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { Notifications } from '@mantine/notifications'
import { mantineTheme } from './styles/mantineTheme'

const container = document.getElementById('root')
const root = createRoot(container as HTMLDivElement)

root.render(
  <StrictMode>
    <MantineProvider theme={mantineTheme} withGlobalStyles withNormalizeCSS>
      <Notifications limit={2} zIndex={99999} />
      <DatesProvider settings={{ firstDayOfWeek: 0, weekendDays: [0, 6] }}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppRoutes />
          </PersistGate>
        </Provider>
      </DatesProvider>
    </MantineProvider>
  </StrictMode>,
)
