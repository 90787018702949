import * as Yup from 'yup'

export const resetPasswordSchema = Yup.object({
  newPassword: Yup.string().required('New Password is required'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

export type ResetPasswordState = Yup.InferType<typeof resetPasswordSchema>

export const resetPasswordInitialState: () => ResetPasswordState = () => ({
  newPassword: '',
  confirmPassword: '',
})
