import { CACHE_FETCH } from '@constants/cache'
import { RoutePaths } from '@interfaces/routePaths'
import { RootState } from '@redux/store'
import { EntityId } from '@reduxjs/toolkit'
import { FullTagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { clearUserState } from '@utils/clearUserState'
import { selectRefreshToken, setAccessToken } from './auth/authSlice'

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL || 'http://localhost:3001/v1',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken
    token && headers.set('authorization', `Bearer ${token}`)

    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  const isUnauthorized = result.error && result.error.status === 401
  const isForbidden = result.error && result.error.status === 403
  const logoutUser = () => {
    const url = new URL(window.location.href)

    clearUserState()
    url.pathname !== RoutePaths.LOGIN &&
      (window.location.href = RoutePaths.LOGIN)
  }

  if (isUnauthorized) logoutUser()

  if (isForbidden) {
    const refreshToken = selectRefreshToken(api.getState())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { data: refreshResult }: any = await baseQuery(
      {
        url: '/auth/refresh',
        method: 'POST',
        body: { refreshToken },
      },
      api,
      extraOptions,
    )

    if (refreshResult?.data) {
      const accessToken = refreshResult.data?.accessToken
      api.dispatch(setAccessToken({ accessToken }))
      result = await baseQuery(args, api, extraOptions)
    } else {
      logoutUser()
    }
  }

  return result
}

export function providesList<R extends EntityId[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map((id) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }]
}

export function invalidatesList(tagType: string) {
  return {
    type: tagType,
    id: 'LIST',
  } as FullTagDescription<never>
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: CACHE_FETCH,
  refetchOnReconnect: true,
  endpoints: () => ({}),
})
