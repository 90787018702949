import * as Yup from 'yup'

export const editSellerSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  website: Yup.string().required('Website is required'),
  description: Yup.string().required('Description is required'),
  hours: Yup.array()
    .of(
      Yup.object({
        day: Yup.string(),
        open: Yup.string(),
        close: Yup.string(),
      }),
    )
    .optional(),
})

export type EditSellerState = Yup.InferType<typeof editSellerSchema>

export const editSellerInitialState: (
  defaults: Partial<EditSellerState>,
) => EditSellerState = (defaults: Partial<EditSellerState>) => {
  return {
    name: defaults?.name ?? '',
    address: defaults?.address ?? '',
    website: defaults?.website ?? '',
    description: defaults?.description ?? '',
    hours: defaults?.hours ?? [],
  }
}
