export enum ProductType {
  UNSPECIFIED = 'Unspecified',
  TSHIRTS = 'T-shirts',
  SWIMWEARS = 'Swimwears',
  SWEATSHIRTS = 'Sweatshirts',
  SKIRTS = 'Skirts',
  SHORTS = 'Shorts',
  SHIRTS = 'Shirts',
  PANTS = 'Pants',
  JERSEYS = 'Jerseys',
  JACKETS = 'Jackets',
  HOODIES = 'Hoodies',
  HATS = 'Hats',
  DRESSES = 'Dresses',
}
