import { sellerAdapter } from '@adapters/sellerAdapter'
import { Content } from '@components/Content/Content'
import { DestructiveConfirm } from '@components/DestructiveConfirm/DestructiveConfirm'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { Seller } from '@interfaces/entities/seller/seller'
import { RoutePaths } from '@interfaces/routePaths'
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  CardSection,
  Drawer,
  Flex,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { useSetStatusMutation } from '@redux/seller/sellerEndpoints'
import { selectShops, selectShopsIsLoading } from '@redux/seller/sellerSlice'
import {
  IconCheck,
  IconClock,
  IconEdit,
  IconExternalLink,
  IconInfoSmall,
  IconMapPin,
  IconShoppingCartOff,
  IconTrash,
  IconUsers,
  IconX,
} from '@tabler/icons-react'
import { buildLink } from '@utils/buildLink'
import { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Users } from './Users'

function ShopTitle({
  seller,
  handleStatusClick,
}: {
  seller: Seller
  handleStatusClick: () => void
}): JSX.Element {
  return (
    <>
      <Title order={5}>{seller.name}</Title>
      <Button
        size="xs"
        leftIcon={
          seller.isActive ? (
            <IconCheck size="0.9rem" />
          ) : (
            <IconShoppingCartOff size="0.9rem" />
          )
        }
        onClick={handleStatusClick}
        variant="light"
        color={seller.isActive ? 'green' : 'gray'}
      >
        {seller.isActive ? 'Active' : 'Inactive'}
      </Button>
    </>
  )
}

export const Details = (): JSX.Element => {
  const { sellerId = '' } = useParams()
  const navigate = useNavigate()
  const [isStatusOpen, setIsStatusOpen] = useState<boolean>(false)
  const [opened, { open, close }] = useDisclosure()
  const sellerEntityState = useAppSelector(selectShops)
  const isLoading = useAppSelector(selectShopsIsLoading)
  const [setSellerStatus, { isLoading: sellerStatusLoading }] =
    useSetStatusMutation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const seller =
    sellerEntityState &&
    !isLoading &&
    sellerAdapter.getSelectors().selectById(sellerEntityState, sellerId)

  const handleManageNavigate = (mode: 'edit' | 'delete') =>
    navigate(`${RoutePaths.SHOPS_MANAGE}/${mode}`)

  const handleEditClick = () => handleManageNavigate('edit')
  const handleDeleteClick = () => handleManageNavigate('delete')
  const handleStatusChange = () => setIsStatusOpen(!isStatusOpen)

  const handleSetStatus = async () => {
    if (isSubmitting || !seller) return

    setIsSubmitting(true)

    try {
      const isActive = seller && seller.isActive
      await setSellerStatus({ sellerId, isActive }).unwrap()

      showNotification({
        message: `${seller.name} status updated!`,
        color: 'green',
      })
    } catch (e) {
      showNotification({
        message: `Unable to update ${seller.name} status!`,
        color: 'red',
      })
    } finally {
      setIsSubmitting(false)
      setIsStatusOpen(false)
    }
  }

  return (
    <>
      <Content>
        {seller ? (
          <>
            <Section
              title={
                <ShopTitle
                  seller={seller}
                  handleStatusClick={handleStatusChange}
                />
              }
            >
              <CardSection
                p="xs"
                sx={(theme) => ({
                  backgroundColor: theme.fn.lighten(theme.colors.gray[0], 0.3),
                })}
              >
                <Group position="apart">
                  <Button
                    variant="default"
                    leftIcon={<IconUsers size="0.9rem" />}
                    onClick={open}
                  >
                    Users
                  </Button>
                  <Group spacing="xs">
                    <Button
                      leftIcon={<IconEdit size="0.9rem" />}
                      onClick={handleEditClick}
                    >
                      Edit
                    </Button>
                    <Button
                      color="red"
                      leftIcon={<IconTrash size="0.9rem" />}
                      onClick={handleDeleteClick}
                    >
                      Delete
                    </Button>
                  </Group>
                </Group>
              </CardSection>
              <CardSection p="md" withBorder>
                <Flex>
                  <div>
                    <IconInfoSmall size="1.8rem" />
                  </div>
                  <Text ml="sm" color="gray.7" my={5} italic span>
                    {seller.description}
                  </Text>
                </Flex>
              </CardSection>
              <CardSection p="md" withBorder>
                <Stack>
                  <Group>
                    <Group spacing="xs">
                      <IconMapPin size="1.5rem" />
                      <Title w={75} order={5} color="gray.7">
                        Address
                      </Title>
                    </Group>
                    <Text>{seller.address}</Text>
                  </Group>
                  <Group>
                    <Group spacing="xs">
                      <IconExternalLink size="1.5rem" />
                      <Title w={75} order={5} color="gray.7">
                        Website
                      </Title>
                    </Group>

                    <Anchor href={buildLink(seller.website)} target="_blank">
                      {seller.website}
                    </Anchor>
                  </Group>
                </Stack>
              </CardSection>
            </Section>
            <DestructiveConfirm
              confirmText={seller.isActive ? 'Set Inactive' : 'Set Active'}
              opened={isStatusOpen}
              onCancel={handleStatusChange}
              onConfirm={handleSetStatus}
              isLoading={sellerStatusLoading}
            >
              <Text>
                Are you sure you would like to mark this seller as{' '}
                <strong>{seller?.isActive ? 'Inactive' : 'Active'}</strong>?
              </Text>
            </DestructiveConfirm>
          </>
        ) : (
          <>
            {!isLoading && (
              <Card withBorder>Unable to find seller profile</Card>
            )}
          </>
        )}
      </Content>

      <Drawer
        opened={opened}
        onClose={close}
        overlayProps={{ opacity: 0.1, blur: 3 }}
        withCloseButton={false}
        styles={{
          header: {
            padding: 0,
          },
          content: {
            padding: 0,
          },
          body: {
            padding: 0,
          },
          title: {
            width: '100%',
          },
        }}
        title={
          <Box
            sx={(theme) => ({
              borderBottom: `solid 1px ${theme.colors.gray[2]}`,
            })}
          >
            <Group position="apart" p="md">
              <Title order={5}>Shop Users</Title>
              <ActionIcon
                size="lg"
                variant="default"
                color="gray.0"
                onClick={close}
              >
                <IconX size="1rem" />
              </ActionIcon>
            </Group>
          </Box>
        }
        position="right"
        size="xl"
      >
        <Users />
      </Drawer>

      <Outlet />
    </>
  )
}
