import { useAppDispatch } from '@hooks/redux'
import { useSetSection } from '@hooks/useSetSection'
import { useGetAllSellersQuery } from '@redux/seller/sellerEndpoints'
import { setShops } from '@redux/seller/sellerSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const Shops = (): JSX.Element => {
  useSetSection('Platform Shops')

  const dispatch = useAppDispatch()

  const { data: sellerEntityState, isLoading, error } = useGetAllSellersQuery()

  useEffect(() => {
    dispatch(setShops({ shops: sellerEntityState, isLoading, error }))
  }, [sellerEntityState, isLoading, error])

  return <Outlet />
}
