import { useParams } from 'react-router-dom'
import { CreateSeller } from './CreateSeller'
import { DeleteSeller } from './DeleteSeller'
import { EditSeller } from './EditSeller'

export const Manage = (): JSX.Element => {
  const { mode } = useParams()

  return (
    <>
      {!mode && <CreateSeller />}
      {mode === 'edit' && <EditSeller />}
      {mode === 'delete' && <DeleteSeller />}
    </>
  )
}
