import { RoutePaths } from '@interfaces/routePaths'
import {
  IconDashboard,
  IconGift,
  IconLock,
  IconPencil,
  IconShirt,
  IconShoppingBag,
} from '@tabler/icons-react'

export const LINKS = [
  {
    label: 'Admin',
    icon: IconLock,
    color: 'blue',
    isAdmin: true,
    match: RoutePaths.ADMIN,
    links: [
      {
        label: 'Overview',
        link: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_OVERVIEW}`,
      },
      {
        label: 'Platform Shops',
        link: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_SHOPS}/${RoutePaths.SHOPS_LIST}`,
        match: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_SHOPS}`,
      },
      {
        label: 'Platform Products',
        link: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_PRODUCTS}/${RoutePaths.ADMIN_PRODUCTS_LIST}`,
        match: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_PRODUCTS}`,
      },
      {
        label: 'Product Brands',
        link: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_BRANDS}/${RoutePaths.ADMIN_BRANDS_LIST}`,
        match: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_BRANDS}`,
      },
      {
        label: 'Platform Users',
        link: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_USERS}/${RoutePaths.ADMIN_USERS_LIST}`,
        match: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_USERS}`,
      },
      {
        label: 'Invite Admin',
        link: `${RoutePaths.ADMIN}/${RoutePaths.ADMIN_INVITE}`,
      },
    ],
  },
  {
    label: 'Dashboard',
    color: 'teal',
    icon: IconDashboard,
    link: RoutePaths.DASHBOARD,
    match: RoutePaths.DASHBOARD,
  },
  {
    label: 'Shops',
    icon: IconShoppingBag,
    color: 'pink',
    link: `${RoutePaths.SHOPS}/${RoutePaths.SHOPS_LIST}`,
    match: RoutePaths.SHOPS,
  },
  {
    label: 'Wishlists',
    icon: IconGift,
    color: 'violet',
    link: RoutePaths.WISHLISTS,
    match: RoutePaths.WISHLISTS,
  },
  {
    label: 'Posts',
    icon: IconPencil,
    color: 'cyan',
    link: RoutePaths.POSTS,
    match: RoutePaths.POSTS,
  },
  {
    label: 'Products',
    icon: IconShirt,
    color: 'orange',
    match: RoutePaths.PRODUCTS,
    links: [
      {
        label: 'Thrift',
        link: `${RoutePaths.PRODUCTS}/${RoutePaths.PRODUCTS_THRIFT}`,
      },
      {
        label: 'Vintage',
        link: `${RoutePaths.PRODUCTS}/${RoutePaths.PRODUCTS_VINTAGE}`,
      },
    ],
  },
]
