import { Box } from '@mantine/core'
import { ReactNode } from 'react'

export interface ContentProps {
  fullWidth?: boolean
  children: ReactNode | ReactNode[]
}
export const Content = (props: ContentProps): JSX.Element => {
  const { fullWidth = false, children } = props

  const width = fullWidth ? '100%' : 725

  return (
    <Box maw={width} p="md">
      {children}
    </Box>
  )
}
