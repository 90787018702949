import { productAdapter } from '@adapters/productAdapter'
import { productAssetAdapter } from '@adapters/productAssetAdapter'
import { EmptyState } from '@components/EmptyState/EmptyState'
import { Section } from '@components/Section/Section'
import { Product } from '@interfaces/entities/product/product'
import { RoutePaths } from '@interfaces/routePaths'
import { Carousel } from '@mantine/carousel'
import {
  Badge,
  CardSection,
  Grid,
  Group,
  Image,
  Input,
  rem,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { EntityState } from '@reduxjs/toolkit'
import {
  IconBarcode,
  IconBuildingStore,
  IconCategory,
  IconHanger,
  IconSparkles,
  IconTrademark,
} from '@tabler/icons-react'
import { useNavigate } from 'react-router'
import { useOutletContext } from 'react-router-dom'

export const List = (): JSX.Element => {
  const navigate = useNavigate()
  const {
    productEntityState,
    isLoading,
  }: {
    productEntityState: EntityState<Product>
    isLoading: boolean
  } = useOutletContext()

  const products = productEntityState
    ? productAdapter.getSelectors().selectAll(productEntityState)
    : []

  const handleClick = (productId: string) =>
    navigate(`../${RoutePaths.ADMIN_PRODUCTS_DETAILS}/${productId}`)

  const productCard = (product: Product) => {
    const productAssets = product.productAssets
      ? productAssetAdapter.getSelectors().selectAll(product.productAssets)
      : []

    return (
      <Grid.Col key={product.id} span={4}>
        <Stack pt="sm">
          <CardSection
            style={{
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              overflow: 'hidden',
              transition: 'box-shadow 0.2s ease',
              cursor: 'pointer',
              backgroundColor: '#fff',
            }}
            onClick={() => handleClick(product.id)}
          >
            <Group pl="sm" pt="xs" pr="xs">
              <Text fw="bold" size="sm" color="gray.7" lineClamp={1}>
                {product.title}
              </Text>
            </Group>
            <Group p="sm" position="center">
              <Badge variant="filled" color={product.isActive ? '' : 'gray'}>
                {product.price}
              </Badge>
              {product.isActive ? (
                <Badge variant="filled" color="teal.5">
                  Active
                </Badge>
              ) : (
                <Badge variant="filled" color="gray">
                  Inactive
                </Badge>
              )}
            </Group>
            {productAssets.length > 0 && (
              <CardSection onClick={(e) => e.stopPropagation()}>
                <Carousel
                  mx="auto"
                  height={200}
                  withIndicators
                  styles={{
                    root: {
                      ':hover .mantine-Carousel-controls': {
                        opacity: 1,
                      },
                    },
                    controls: {
                      transition: 'opacity 150ms',
                      opacity: 0,
                    },
                    indicator: {
                      width: rem(4),
                      height: rem(4),
                      transition: 'width 250ms ease',

                      '&[data-active]': {
                        width: rem(15),
                      },
                    },
                  }}
                >
                  {productAssets.map((asset) => (
                    <Carousel.Slide key={asset.id}>
                      <Image
                        src={asset.url}
                        height={200}
                        fit="cover"
                        styles={{
                          image: {
                            objectPosition: 'top',
                            width: '100%',
                            height: '100%',
                            filter: product.isActive
                              ? 'none'
                              : 'grayscale(100%)',
                          },
                        }}
                      />
                    </Carousel.Slide>
                  ))}
                </Carousel>
              </CardSection>
            )}
            <Text size="xs" color="gray.7" p="sm">
              <Stack spacing="xs">
                <Group spacing="xs">{product.category}</Group>
                <Group spacing="xs">
                  <IconBuildingStore size="1rem" />
                  {product?.seller?.name}
                </Group>
                <Group spacing="xs">
                  <IconBarcode size="1rem" />
                  <Text lineClamp={1}>{product.sku}</Text>
                </Group>

                <Group spacing="xs">
                  <IconTrademark size="1rem" />
                  <Text lineClamp={1}>
                    {product.productBrand
                      ? product.productBrand.name
                      : product.brand}
                  </Text>
                </Group>
                <Group>
                  <Group spacing="xs">
                    <IconSparkles size="1rem" />
                    {product.condition}
                  </Group>
                </Group>
                <Group>
                  <Group spacing="xs">
                    <IconCategory size="1rem" />
                    {product.department}
                  </Group>
                </Group>
                <Group>
                  <Group spacing="xs">
                    <IconHanger size="1rem" />
                    {product.type}
                  </Group>
                </Group>

                <Text lineClamp={1}>{product.description}</Text>
              </Stack>
            </Text>
          </CardSection>
        </Stack>
      </Grid.Col>
    )
  }

  return (
    <>
      {productEntityState && !isLoading && (
        <>
          {products.length > 0 ? (
            <>
              <Section
                title={
                  <Title order={5}>
                    Platform Products{' '}
                    <Text color="gray.6" fw="normal" span>
                      ({products.length})
                    </Text>
                  </Title>
                }
              >
                <Stack pt="sm">
                  <Input placeholder="Search Placeholder - ** Not Implemented **" />
                </Stack>
              </Section>
              <Grid>{products.map(productCard)}</Grid>
            </>
          ) : (
            <>
              <EmptyState type={`platform products`} />
            </>
          )}
        </>
      )}
    </>
  )
}
