import { Content } from '@components/Content/Content'
import { FormError } from '@components/FormError/FormError'
import { Section } from '@components/Section/Section'
import { useSetSection } from '@hooks/useSetSection'
import { MutationError } from '@interfaces/mutationError'
import { Roles } from '@interfaces/roles'
import {
  Button,
  CardSection,
  Select,
  SelectItem,
  Stack,
  TextInput,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useInviteMutation } from '@redux/auth/authEndpoints'
import {
  inviteAdminInitialState,
  inviteAdminSchema,
  InviteAdminState,
} from '@schemas/invite/inviteAdmin'
import { useState } from 'react'

export const Invite = (): JSX.Element => {
  useSetSection('Invite Admin User')

  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()

  const [invite, { isSuccess }] = useInviteMutation()

  const { onSubmit, isDirty, getInputProps, reset } = useForm({
    initialValues: inviteAdminInitialState(),
    validate: yupResolver(inviteAdminSchema),
  })

  const roles = Object.values(Roles).reduce(
    (acc: SelectItem[], role: string) => {
      if (role !== Roles.CLIENT && role !== Roles.CUSTOMER)
        acc.push({
          label: role,
          value: role,
        })

      return acc
    },
    [],
  )

  const handleSubmit = async (values: InviteAdminState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      await invite(values).unwrap()

      showNotification({
        message: `Invite sent!`,
        color: 'green',
      })

      reset()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Content>
      <Section title="Invite Admin">
        <CardSection p="md">
          <form
            onSubmit={onSubmit((values: InviteAdminState) =>
              handleSubmit(values),
            )}
          >
            <Stack>
              {!isSuccess && formError && <FormError error={formError} />}
              <TextInput
                type="email"
                placeholder="Email"
                {...getInputProps('email')}
              />
              <Select
                data={roles}
                placeholder="Role"
                dropdownPosition="bottom"
                withinPortal
                {...getInputProps('role')}
              />
              <Button type="submit" loading={isDirty() && isSubmitting}>
                Send Invite
              </Button>
            </Stack>
          </form>
        </CardSection>
      </Section>
    </Content>
  )
}
