import { sellerUsersAdapter } from '@adapters/sellerUsersAdapter'
import { Seller } from '@interfaces/entities/seller/seller'
import { SellerResponse } from '@interfaces/entities/seller/sellerResponse'
import { sellerUserSerializer } from './sellerUserSerializer'

export function sellerSerializer(seller: SellerResponse): Seller {
  const { id, name, website, address, description, isActive, sellerUsers } =
    seller

  const users = sellerUsers
    ? {
        users: sellerUsersAdapter.setAll(
          sellerUsersAdapter.getInitialState(),
          sellerUsers.map(sellerUserSerializer),
        ),
      }
    : {}

  return {
    id,
    name,
    website,
    address,
    description,
    isActive,
    ...users,
  }
}
