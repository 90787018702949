import { Shell } from '@components/Shell/Shell'
import { Outlet, ScrollRestoration } from 'react-router-dom'

export const BasePath = (): JSX.Element => {
  return (
    <>
      <Shell>
        <Outlet />
      </Shell>
      <ScrollRestoration />
    </>
  )
}
