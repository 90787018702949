import { EndpointPaths } from '@interfaces/endpointPaths'
import { apiSlice } from '@redux/apiSlice'

export const accountEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: (body) => ({
        url: EndpointPaths.ACCOUNT_CHANGE_PASSWORD,
        method: 'POST',
        body: { ...body },
      }),
    }),
  }),
})

export const { useChangePasswordMutation } = accountEndpoints
