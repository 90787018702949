export enum Hours {
  MIDNIGHT = '12:00',
  MIDNIGHT30 = '12:30',
  ONE = '1:00',
  ONE30 = '1:30',
  TWO = '2:00',
  TWO30 = '2:30',
  THREE = '3:00',
  THREE30 = '3:30',
  FOUR = '4:00',
  FOUR30 = '4:30',
  FIVE = '5:00',
  FIVE30 = '5:30',
  SIX = '6:00',
  SIX30 = '6:30',
  SEVEN = '7:00',
  SEVEN30 = '7:30',
  EIGHT = '8:00',
  EIGHT30 = '8:30',
  NINE = '9:00',
  NINE30 = '9:30',
  TEN = '10:00',
  TEN30 = '10:30',
  ELEVEN = '11:00',
  ELEVENT30 = '11:30',
}
