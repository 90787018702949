// RTK Query Tags
export enum ApiTagTypes {
  SELLERS_ALL = 'SellersAll',
  SELLERS_USER = 'SellersUser',
  BRANDS = 'Brands',
  PRODUCTS = 'Products',
  PRODUCTS_ALL = 'ProductsAll',
  USERS = 'Users',
  WISHLIST = 'Wishlist',
  POSTS = 'Posts',
  FOLLOWERS = 'Followers',
}
