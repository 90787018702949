import { productAssetAdapter } from '@adapters/productAssetAdapter'
import { Product } from '@interfaces/entities/product/product'
import { ProductResponse } from '@interfaces/entities/product/productResponse'
import { User } from '@interfaces/entities/user/user'
import { attributesToObject } from '@utils/attributesToObject'
import currency from 'currency.js'
import { productAssetSerializer } from './productAssetSerializer'
import { sellerSerializer } from './sellerSerializer'

export function productSerializer(product: ProductResponse): Product {
  const {
    id,
    brand,
    user,
    sku,
    title,
    description,
    price,
    size,
    condition,
    type,
    department,
    category,
    isActive,
    productAttributes,
    productBrand,
    productAssets,
    seller,
  } = product

  const _price = currency(price, { fromCents: true }).format()
  const attributes = attributesToObject(productAttributes)
  const _productBrand = productBrand ? { productBrand: productBrand.brand } : {}
  const _productAssets = productAssets
    ? {
        productAssets: productAssetAdapter.setAll(
          productAssetAdapter.getInitialState(),
          productAssets.map(productAssetSerializer),
        ),
      }
    : {}
  const _seller = seller ? { seller: sellerSerializer(seller) } : {}

  return {
    id,
    user: user as User,
    brand,
    sku,
    title,
    description,
    price: _price,
    size,
    condition,
    type,
    department,
    category,
    isActive,
    ..._productBrand,
    ..._productAssets,
    ..._seller,
    ...attributes,
  } as Product
}
