import { SellerUserResponse } from '@interfaces/entities/sellerUsers/sellerUserResponse'
import { User } from '@interfaces/entities/user/user'

export function sellerUserSerializer(sellerUser: SellerUserResponse): User {
  const {
    id,
    role: sellerRole,
    user: { id: userId, name, email, role, isActive },
  } = sellerUser

  return {
    id: userId,
    name,
    email,
    role,
    isActive,
    sellerRole,
    sellerUserId: id,
  }
}
